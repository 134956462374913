import { NavLink, useRouteLoaderData, Outlet} from "react-router-dom"
import { useState } from "react";
import { createPortal } from "react-dom";

import { FormatDateToLocalString } from "../utils/formaters";

import { ComunaSidePanel } from "../components/ComunaSidePanel";

const office_icon = require("../img/icons/office.png");
const home_icon = require("../img/icons/home.png");
const clock_icon = require("../img/icons/clock.png");
const chevron_down_dark = require("../img/icons/chevron-down-dark.png");
const pencil_icon  = require("../img/icons/pencil.png");

const junior_badge = require("../img/icons/junior-badge.png");
const mid_badge = require("../img/icons/mid-badge.png");
const senior_badge = require("../img/icons/senior-badge.png");
const lead_badge = require("../img/icons/lead-badge.png");

export function RequestDetailCard(){

    const {resource_request_source}:any = useRouteLoaderData("resource_request_detail");

    const [activeListMinimized, setActiveListMinimized] = useState(false);

    console.log("Resource Request detail card: ", resource_request_source);

    function GetHiredCount(){
        var count = 0;
        resource_request_source.applications.forEach((application:any)=>{
            if(application.proposal!=null &&  application.proposal.state===1){
                count++;
            }
        })
        return count;
    }

    function GetTotalCost() {
		var total = 0;
		resource_request_source.benefits.map((item:any) => {
			total += item.cost;
		});
		return total.toLocaleString("en-US").split(".")[0];
	}

    function ExperienceBadge(){
        if(resource_request_source.seniority==="junior"){
            return <img src={junior_badge} alt="" style={{width:"auto", height:24}} className="mr-2"/>
        }else if(resource_request_source.seniority==="mid-level"){
            return <img src={mid_badge} alt="" style={{width:"auto", height:24}} className="mr-2"/>
        }else if(resource_request_source.seniority==="senior"){
            return <img src={senior_badge} alt="" style={{width:"auto", height:24}} className="mr-2"/>
        }else if(resource_request_source.seniority==="lead"){
            return <img src={lead_badge} alt="" style={{width:"auto", height:24}} className="mr-2"/>
        }
    }

    function ExperienceYears(){
        if(resource_request_source.seniority==="junior"){
            return "1-3 years of experience"
        }else if(resource_request_source.seniority==="mid-level"){
            return "3-6 years of experience"
        }else if(resource_request_source.seniority==="senior"){
            return "6-10 years of experience"
        }else if(resource_request_source.seniority==="lead"){
            return "10+ years of experience"
        }
    }

    function Skills() {
		return (
			<div className="flex flex-row flex-wrap mt-2">
				{resource_request_source.skills_labels !== undefined &&
					resource_request_source.skills_labels.map((skill: any, i: number) => {
						return (
							<span key={i} className="skill-badge tooltip mb-2 mr-2">
								{skill}
							</span>
						);
					})}
			</div>
		);
	}

    function Benefits(){
        return(
            <div className="flex flex-row flex-wrap mt-2">
                {resource_request_source.benefits.map((item:any)=>{
                    return(
                        <p className="benefit-badge tooltip mr-2">
                            <span>{item.name}</span>
                            <span className="tooltiptext"  style={{ width: 324 }}>
                                {item.description}
                                <b>
									{" "}
									${item.cost.toLocaleString("en-US") + " "}{" "}
									{item.one_time_only ? "(One time)" : "(Monthly)"}
								</b>
                            </span>
                        </p>
                    )
                })}
            </div>
        )
    }

    function Labels() {
		return (
			<div className="flex flex-row flex-wrap mt-2">
				{resource_request_source.labels !== undefined &&
					resource_request_source.labels.map((label: any, i: number) => {
						return (
							<span key={"label-"+i} className="label-badge tooltip mb-2 mr-2">
								{label.name}
							</span>
						);
					})}
			</div>
		);
	}


    return(
    <ComunaSidePanel return_to={"/requests/"+resource_request_source.id} >

        <div className="flex flex-row justify-between items-center">
            <h2 className="text-left mt-6">{resource_request_source.role_name}</h2>
            <NavLink to="edit" className="comuna-secondary-button sm mt-4 ">
                <img src={pencil_icon} style={{width:24, height:24, marginRight:8}} alt="" />
                Edit Request
            </NavLink>
        </div>
        
        <p className="text-left font-bold text-black mt-6">Request Internal Labels</p>
        <Labels/>

        <p className="flex flex-row items-center justify-between bg-purple-light mt-4 p-6 rounded-lg flex-1">
            <span className="font-bold text-black text-md mr-4"> <span style={{color:"#298C69"}}>{GetHiredCount()}</span>/{resource_request_source.quantity} Hired Positions</span>
            <span className="active-badge text-xs" style={{position:"relative", top:-10, right: -10}}>Active request</span>
        </p>

        {resource_request_source.modality!=="remote" &&
        <p className="flex flex-row rounded-lg justify-between items-center flex-1 bg-purple-light mt-4 p-4">
            <div className="flex flex-row">
                <img src={office_icon} alt="" style={{width:24, height:24}} className="mr-2" />
                <span className="font-bold text-black text-md">Local hub office</span>
            </div>
            <p className="text-black bg-gray-2 p-2 rounded-lg">
                {resource_request_source.location ? resource_request_source.location:"Unkown"}
            </p>
        </p>
        }

        {resource_request_source.modality==="remote" &&
        <p className="flex flex-row rounded-lg justify-between items-center flex-1 bg-purple-light mt-4 p-6">
            <div className="flex flex-row">
                <img src={home_icon} alt="" style={{width:24, height:24}} className="mr-2" />
                <span className="font-bold text-black text-md">Fully remote</span>
            </div>
        </p>
        }

        <div className="flex flex-row">
            <p className="rounded-lg flex-1 bg-purple-light mt-4 p-4 mr-4 text-black font-bold flex flex-row justify-center items-center">
                <img src={clock_icon} alt="" style={{width:24, height:24}} className="mr-2"/>
                <span>{FormatDateToLocalString(resource_request_source.start_date)}</span>
            </p>
            <p className="rounded-lg items-center flex-1 bg-purple-light mt-4 p-4 text-black font-bold flex flex-row justify-center items-center">
                {ExperienceBadge()}
                <span>{ExperienceYears()}</span>
            </p>
        </div>

        <div className="rounded-lg flex-1 bg-purple-light mt-4 p-4 flex flex-col items-start">
            <b className="text-sm">Skills</b>
            <Skills/>
        </div>

        <div className="rounded-lg flex-1 bg-lime mt-4 p-4 flex flex-col items-start">
            <b className="text-sm">Perks and benefits</b>
            <Benefits/>

            <p className="text-black flex flex-col bg-white w-full p-3 px-6 rounded-lg">
                <div className="flex flex-row justify-between items-center w-full">
    			    <button className="flex flex-row items-center" onClick={()=>{ setActiveListMinimized(!activeListMinimized) }}>
                        <img src={chevron_down_dark} alt="" style={{width:32, height:32}} className="mr-2"/>
                        <span>Estimated: perks and benefit cost{" "}</span>
                    </button>
				    <b className="text-3xl font-bold"> <span className="text-gray-4">$</span> {GetTotalCost()}</b>{" "}
                </div>
                <div
				className="flex flex-col py-2 overflow-hidden transition"
				style={{ maxHeight: activeListMinimized ? 0 : 50 * resource_request_source.benefits.length }}>
				{resource_request_source.benefits.map((b:any) => {
                    return(
                    <div className="flex flex-row justify-between py-2 items-center">
                        <span>{b.name}</span>
                        <b>
                            ${b.cost.toLocaleString("en-US")}{" "}
                            {b.one_time_only ? "(One time)" : "/ Month"}
                        </b>
                    </div>)
				})}
			    </div>
			</p>
        </div>

        <b className="text-left w-full flex py-4">Role Description</b>
        <p className="bg-purple-light rounded-lg p-4 py-6 text-left">
            {resource_request_source.position_overview}
        </p>


        {createPortal(<Outlet/>, document.body)}

    </ComunaSidePanel>)
}