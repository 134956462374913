import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { Loader } from "./loader";

export function SignIn({ onSignedInEvent }) {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const { token, verified, setVerified } = useContext(UserContext);

	const [error, setError] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		signIn();
	};

	async function signIn() {
		setError("");
		setLoading(true);
		var newToken = "";
		try {
			console.log("sign in info: ", email, password);

			const signInReturn = await ComunaAPI.signIn(email, password);
			console.log("token is: " + signInReturn.token);
			newToken = signInReturn.token + "";
			console.log("new token = " + newToken);
		} catch {
			setError("Invalid email or password");
		} finally {
			setLoading(false);
		}
		if (newToken !== "") {
			onSignedInEvent(newToken);
		}
	}

	return (
		<form className="w-full pt-32" onSubmit={handleSubmit}>
			<h2>Sign In</h2>
			<p className="mb-16">Sign in your account</p>

			<div className="mb-4">
				<label htmlFor="email">Username</label>
				<input
					className="comuna-input"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					type="text"
					placeholder="username"
					id="username"
					name="username"
				/>
			</div>
			<div className="mb-4">
				<label htmlFor="email">Password</label>
				<input
					className="comuna-input"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					type="password"
					placeholder="yourpassword"
					id="password"
					name="password"
				/>
			</div>

			{error !== "" && (
				<label className="mt-8 text-red-500">
					{" "}
					{error} <br />
				</label>
			)}
			<button className="comuna-primary-button mt-8" type="submit">
				{loading ? <Loader /> : "Sign In"}
			</button>
		</form>
	);
}
