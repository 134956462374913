import { useEffect, useState } from "react";
import InterviewItem from "./InterviewsItem";

interface InterviewsComponentProps {
	application: any;
}

const InterviewsComponent: React.FC<InterviewsComponentProps> = ({
	application,
}) => {
	const [items, setItems] = useState<any>([]);

	const [empty, setEmpty] = useState<boolean>(false);

	useEffect(() => {
		var tItems = [];
		if (
			application.interview_calendar_link !== "" &&
			application.interview_calendar_link !== null
		) {
			var itemInfo = {
				info: application.interview_calendar_link + "",
				state: "inviteSent",
			};
			tItems.push(itemInfo);
		} else if (application.interview_accepted_date !== null) {
			var parsedDate = application.inteview_accepted_date.split("T")[0];
			var parsedHourArray = application.inteview_accepted_date
				.split("T")[1]
				.split(":");
			var parsedHour = parsedHourArray[0] + ":" + parsedHourArray[1];
			var parsedDateString = parsedDate + " " + parsedHour;
			console.log("parsedDateString: ", parsedDateString);
			var itemInfo = {
				info: parsedDateString,
				state: "scheduled",
			};

			tItems.push(itemInfo);
		} else if (
			application.interview_available_dates !== "" &&
			application.interview_available_dates !== undefined &&
			application.interview_available_dates !== null
		) {
			var datesArray = application.interview_available_dates.split(",");
			console.log("datesArray: ", datesArray);
			if (datesArray.length > 1) {
				for (var i = 0; i < datesArray.length - 1; i++) {
					// console.log("available  " + i + " :", datesArray[i]);
					var parsedDate = datesArray[i].split(" ")[0];
					var parsedHourArray = datesArray[i].split(" ")[1].split(":");
					var parsedHour = parsedHourArray[0] + ":" + parsedHourArray[1];
					var parsedDateString = parsedDate + " " + parsedHour;
					console.log("parsedDateString: ", parsedDateString);
					var itemInfo = {
						info: parsedDateString,
						state: "inviteSent",
					};
					tItems.push(itemInfo);
				}
			}
		} else {
			setEmpty(true);
		}

		setItems(tItems);
	}, []);

	return (
		<div className="w-full h-full">
			{items !== null &&
				items.map((item: any, index: number) => {
					return (
						<InterviewItem
							key={index}
							interviewInfo={item.info}
							interviewState={item.state}
						/>
					);
				})}
			{empty && (
				<div className="w-full h-full flex flex-row justify-center items-center">
					<p className="text-gray-400 text-xl">No interviews available</p>
				</div>
			)}
		</div>
	);
};

export default InterviewsComponent;
