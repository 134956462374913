import { useContext, useEffect, useRef, useState } from "react";
import { ComunaAPI } from "../managers/ComunaAPI";
import { formatTimestamp } from "../utils/formaters";
import { UserContext } from "../Providers/UserContext";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ProposalFromServer } from "../types";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";

import { Loader } from "./loader";

const sendIcon = require("../img/icons/sendMsgIcon.png");


interface MessageComponentProps {
	proposal?: any;
	application?: any;
}

const ApplicationmessageTab: React.FC<MessageComponentProps> = ({
	application = null
}) => {
	const token = LocalStorageManager.getToken();
	const { user }: any = useContext(UserContext);
	const noteScrollContainer = useRef<HTMLDivElement>(null);

	const [notes, setNotes] = useState(application ? [...application.notes, ...application.notes_proposal]: []);

	const [newMessage, setNewMessage] = useState<string>();


	const [email, setEmail] = useState(user.username);

	useEffect(() => {
		ScrollNotesToTheBottom();
		updateNotes();
		const interval = setInterval(() => {
			updateNotes();
		}, 5000);
		return () => clearInterval(interval);
	}, [application]);

	async function updateNotes() {
        if(application===null){
            return;
        }
		var token = await LocalStorageManager.getToken();
		const updatedApp = await ComunaStaffAPI.GetMessages(token, application.id);
		setNotes([...updatedApp.notes, ...updatedApp.notes_proposal]);
		ScrollNotesToTheBottom();
	}

	const ScrollNotesToTheBottom = () => {
		window.setTimeout(() => {
			if (noteScrollContainer.current) {
				noteScrollContainer.current.scrollTop =
					noteScrollContainer.current.scrollHeight;
			}
		}, 250);
	};

	const SendMessage = async () => {
        if(application===null){
            return;
        }
		try {
			if (newMessage !== "") {
				await ComunaStaffAPI.SendMessage(token, application.id, { update: "note", content: newMessage,});
				setNewMessage("");
			}
		} catch (error) {
			alert("There was an error sending your message, please try again later");
		} finally {
			await updateNotes();
			ScrollNotesToTheBottom();
		}
	};

    if(application===null){
        return(
            <div className="h-full w-full flex items-center justify-center">
                <Loader/>
            </div>
        )  
    }

	return (
		<div className="flex flex-col w-full rounded-b-2xl p-3" style={{height:"90%"}}>
			<div
				ref={noteScrollContainer}
				className="bg-white flex flex-col" style={{flex:"1 1 1px", overflowY:"auto"}} >
				{notes !== undefined &&
					notes.map((note: any, i: number) => {
						return (
							<>
								{note !== undefined && (
									<div
										key={i}
										className={
											"flex flex-col mb-4 " +
											(note.creator === email ? "items-end" : "items-start")
										}>
										<div className="flex flex-row">
											<span className="font-bold">
												{note.creator +
													(note.creator_profile === "comuna_staff"
														? " from Comuna"
														: " ")}
											</span>
											<span className="text-xs ml-2 mt-1 text-gray-600">
												{formatTimestamp(note.date_created)}
											</span>
										</div>
										<p
											className={
												"  p-3 mt-2 " +
												(note.creator === email
													? "rounded-l-xl rounded-br-xl text-white bg-comuna-purple"
													: "rounded-r-xl rounded-bl-xl text-black bg-comuna-purple-light-alpha")
											}>
											{note.content}
										</p>
									</div>
								)}
							</>
						);
					})}
			</div>
			<div className="flex flex-row mt-5 items-end">
				<input
					value={newMessage}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setNewMessage(e.target.value)
					}
					onKeyDown={(e) => {
						//if enter send  message
						if (e.key === "Enter") {
							SendMessage();
						}
					}}
					className="w-full h-12 bg-white mr-2 rounded-xl bg-comuna-purple-light-alpha p-4"
					placeholder="Send a message to the candidate and the Comuna Staff"
					type="text"
				/>
				<img src={sendIcon} onClick={SendMessage}></img>
			</div>
		</div>
	);
};

export default ApplicationmessageTab;
