class AppManager {
    constructor() {
        this.protocolBase = 'comuna-desktop-app://';
    }

    performCandidateSearch(token, resource_id) {
        const url = `${this.protocolBase}search-candidates?token=${encodeURIComponent(token)}&id=${encodeURIComponent(resource_id)}`;
        this.openUrl(url);
        console.log('Protocol request sent:', url);
    }

    addCandidate(token, candidate_id) {
        const url = `${this.protocolBase}add-candidate?token=${encodeURIComponent(token)}&id=${encodeURIComponent(candidate_id)}`;
        this.openUrl(url);
        console.log('Protocol request sent:', url);
    }

    addAllCandidates(token, resource_id) {
        const url = `${this.protocolBase}add-all-candidates?token=${encodeURIComponent(token)}&id=${encodeURIComponent(resource_id)}`;
        this.openUrl(url);
        console.log('Protocol request sent:', url);
    }

    openUrl(url) {
        window.location.href = url;
    }
}

const appManager = new AppManager();
export default appManager;
