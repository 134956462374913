import { useState, useEffect } from 'react';
import { useRouteLoaderData } from "react-router-dom";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from '../managers/ComunaStaffAPI';
import AppManager from '../managers/AppManager';

import { ComunaSidePanel } from "../components/ComunaSidePanel";

const empty_avatar = require("../img/icons/emptyAvatar.png");

interface Candidate {
    id: string;
    page_content: string;
    profile_picture_url?: string;
    source_url: string;
    state: 'CREATED' | 'APPLICATION_GENERATED' | 'REJECTED';
    is_processing?: boolean;
}

export function CandidateSearchCard() {
    const [candidates, setCandidates] = useState<Candidate[]>([]);
    const { resource_request_source }: any = useRouteLoaderData("resource_request_detail");
    const [isSearching, setIsSearching] = useState(false);
    const [is_processingAll, setis_processingAll] = useState(false);

    useEffect(() => {
        fetchCandidates();
    }, []);

    async function fetchCandidates() {
        const token = await LocalStorageManager.getToken();
        const response = await ComunaStaffAPI.GetSearchResults(token, resource_request_source.id);
        if (response != null) {
            setCandidates(response);
        }
    }

    useEffect(() => {
        const token = LocalStorageManager.getToken();
        const intervalId = setInterval(async () => {
            if (!isSearching && candidates.length === 0) {
                setIsSearching(true);
                const response = await ComunaStaffAPI.GetSearchResults(await token, resource_request_source.id);
                if (response && response.length > 0) {
                    setCandidates(response);
                    clearInterval(intervalId);
                }
                setIsSearching(false);
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isSearching, candidates.length]);

    const handlePerformSearch = async () => {
        setIsSearching(true);
        const token = await LocalStorageManager.getToken();
        AppManager.performCandidateSearch(token, resource_request_source.id);
    };

    async function handleAddCandidate(candidate: Candidate, index: number) {
        const updatedCandidates = [...candidates];
        updatedCandidates[index].is_processing = true;
        setCandidates(updatedCandidates);

        const token = await LocalStorageManager.getToken();
        AppManager.addCandidate(token, candidate.id);

    };

    async function handleRejectCandidate(candidate: Candidate, index: number) {
        const updatedCandidates = [...candidates];
        updatedCandidates[index].is_processing = true;
        setCandidates(updatedCandidates);

        const token = await LocalStorageManager.getToken();
        try {
            await ComunaStaffAPI.RejectSearchResult(token, candidate.id);
            updatedCandidates[index].state = 'REJECTED';
            updatedCandidates[index].is_processing = false;
            setCandidates([...updatedCandidates]);
        } catch (error: any) {
            alert(`Failed to reject candidate: ${error.message}`);
            updatedCandidates[index].is_processing = false;
            setCandidates([...updatedCandidates]);
        }
    };

    async function addAllCandidates() {
        console.log("Adding all candidates:", candidates);
        setis_processingAll(true);
        const token = await LocalStorageManager.getToken();
        AppManager.addAllCandidates(token, resource_request_source.id);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!is_processingAll && candidates.some(candidate => !candidate.is_processing)) {
                fetchCandidates();
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [is_processingAll, candidates]);

    return (
        <ComunaSidePanel return_to={"/requests/" + resource_request_source.id}>
            <div className="flex flex-row justify-between items-center">
                <h2 className="text-left mt-6">Candidate search</h2>
                {candidates.length > 0 &&
                    <button
                        className="mb-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={addAllCandidates}
                        disabled={is_processingAll}
                    >
                        {is_processingAll ? 'Processing...' : 'Add All Candidates'}
                    </button>
                }
            </div>
            {candidates.length > 0 ? (
                <div className="flex flex-row pt-6">
                    <ul>
                        {candidates.map((candidate, index) => {
                            const contentParts = candidate.page_content.split('\n');
                            const displayContent = contentParts.some(part => part.includes('has a premium account')) ? contentParts[1].includes('test') ? contentParts[2] : contentParts[1] : contentParts[0];
                            
                            let button;
                            if (candidate.state === 'CREATED') {
                                button = <>
                                    <button
                                        className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                        disabled={candidate.is_processing || is_processingAll}
                                        onClick={() => handleAddCandidate(candidate, index)}
                                    >
                                        {candidate.is_processing || is_processingAll ? 'Processing...' : 'Add Candidate'}
                                    </button>
                                    <button
                                        className="ml-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                        disabled={candidate.is_processing || is_processingAll}
                                        onClick={() => handleRejectCandidate(candidate, index)}
                                    >
                                        {candidate.is_processing || is_processingAll ? 'Processing...' : 'Reject Candidate'}
                                    </button>
                                </>;
                            } else if (candidate.state === 'APPLICATION_GENERATED') {
                                button = <span className="ml-4 text-green-500">Added</span>;
                            } else if (candidate.state === 'REJECTED') {
                                button = <span className="ml-4 text-red-500">Rejected</span>;
                            }
    
                            return (
                                <li key={index} className="mb-4 p-2 border-b flex items-center">
                                    <img 
                                        src={candidate.profile_picture_url || empty_avatar}
                                        alt="Profile"
                                        style={{ width: 100, height: 100, borderRadius: '50%' }}
                                    />
                                    <div className="flex-grow ml-4">
                                        <h3>{displayContent}</h3>
                                        <a href={candidate.source_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">View LinkedIn Profile</a>
                                    </div>
                                    {button}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : (
                <>
                    <p className="text-left" style={{ marginTop: 10, marginBottom: 2 }}>No search results available.</p>
                    <button
                        onClick={handlePerformSearch}
                        className="comuna-primary-button sm mt-4"
                        disabled={isSearching}>
                        {isSearching ? 'Searching...' : 'Linkedin search'}
                    </button>
                </>
            )}
        </ComunaSidePanel>
    );}
