import { useState, useEffect } from "react";
import { useRouteLoaderData, useLoaderData, useParams } from "react-router-dom";

import { role, skill, label } from "../types/roleTypes";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { ValidateRoles } from "../utils/roleValidator";

import { Loader as LoaderSpinner } from "../components/loader";
import { RoleForm } from "../components/ResourceRequest/RoleForm_v1.1";
import { BenefitsForm } from "../components/ResourceRequest/BenefitsForm_v1.1";
import { createPortal } from "react-dom";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import PricingTable from "../components/ResourceRequest/PricingTable";

const xIcon = require("../img/icons/x.png");
const rightArrow = require("../img/icons/arrow-right.png");

interface LoaderData {
	availableSkills: any;
	availableLabels: any;
	roleRecs: any;
	availableBenefits: any;
	benefitsCategories: any;
	benefits_plans: any;
}

export async function Loader() {
	const data: LoaderData = {
		availableSkills: [],
		availableLabels: [],
		roleRecs: [],
		availableBenefits: [],
		benefitsCategories: [],
		benefits_plans: [],
	};

	var token = await LocalStorageManager.getToken();

	//load fields for form.
	const fields = await ComunaAPI.getResReqFields(token); //Get Resource Request Fields for form
	if (fields != null) {
		data.availableBenefits = fields.benefits;
		data.benefitsCategories = fields.benefits_categories;
		data.benefits_plans = fields.benefits_plans;

		const roleRecsOptions: any = [];
		if (fields.role_recommendations.length > 0) {
			fields.role_recommendations.map((role: any) => {
				roleRecsOptions.push({
					value: role.role_name,
					label: role.role_name,
				});
			});
			data.roleRecs = roleRecsOptions;
		}

		var newSkillOptions: any[] = [];
		fields.skills.map((item: any) => {
			newSkillOptions.push({
				label: item.name,
				value: item.id,
			});
		});
		data.availableSkills = newSkillOptions;

		var newLabelOptions: any[] = [];
		fields.labels.map((item: any) => {
			newLabelOptions.push({
				label: item.name,
				value: item.id,
			});
		});
		data.availableLabels = newLabelOptions;
	}
	return data;
}

export function EditRequestForm() {
	const { resource_request_source }: any = useRouteLoaderData("resource_request_detail");

	const resourceRequests = [resource_request_source]

	const { request_id }: any = useParams();
	const {
		availableSkills,
		availableLabels,
		roleRecs,
		benefitsCategories,
		availableBenefits,
		benefits_plans
	}: any = useLoaderData();
	const [selectedPlan, setSelectedPlan] = useState<string>("Essentials");
	const [selectedBenefits, setSelectedBenefits] = useState<Record<number, number>>({});

	const [step, setStep] = useState<number>(1);
	const [roles, setRoles] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const [success, setSuccess] = useState<boolean>(false);
	const [confirmLeave, setConfirmleave] = useState<boolean>(false);

	useEffect(() => {
		//we start with a default role
		const default_role: role = {
			id: -1,
			roleName: "",
			roleOrganization: null,
			roleQuantity: 1,
			roleStartDate: "",
			roleDescription: "",
			roleSeniority: "",
			roleModality: "",
			roleLocation: "",
			roleSkills: [],
			roleLabels: [],
			roleDetails: "",
			benefits: [],
			nameCheck: true,
			organizationCheck: true,
			descriptionCheck: true,
			skillsCheck: true,
			labelsCheck: true,
			dateGood: true,
			good: true,
			interviewWeights: {},
		};

		resourceRequests.forEach((r: any) => {
			if (r.id === parseInt(request_id)) {
				//populate the default role with the content we got from the server
				default_role.id = r.id;
				default_role.roleName = r.role_name;
				default_role.roleOrganization = r.organization_profile;
				default_role.roleQuantity = r.quantity;
				default_role.roleStartDate = r.start_date;
				default_role.roleDescription = r.position_overview;
				default_role.roleSeniority = r.seniority;
				default_role.roleModality = r.modality;
				default_role.roleLocation = r.location;
				default_role.roleDetails = r.additional_details;
				default_role.interviewWeights = r.interview_weights;

				//populate skills based on the available skills
				const existingSkills: skill[] = [];
				r.skills.forEach((skill_id: any) => {
					availableSkills.forEach((as: skill) => {
						if (as.value === skill_id) {
							existingSkills.push(as);
						}
					});
				});
				default_role.roleSkills = existingSkills;

				//populate labels
				const existingLabels: label[] = [];
				r.labels.forEach((label: any) => {
					availableLabels.forEach((as: label) => {
						if (as.value === label.id) {
							existingLabels.push(as);
						}
					});
				});
				default_role.roleLabels = existingLabels;

				setRoles([default_role]);

				//populate benefits array:
				if (r.benefits.length > 0 && r.benefits[0].plan_name) {
					setSelectedPlan(r.benefits[0].plan_name);

					const newBenefits: any[] = [];
					const initialSelectedBenefits: Record<number, number> = {};

					r.benefits.forEach((benefit: any) => {
						newBenefits.push(benefit.id);

						const category = benefits_plans.find((plan: any) => plan.name === benefit.category_name);
						if (category) {
							const benefitInCategory = category.benefits.find((b: any) => b.name === benefit.name);
							if (benefitInCategory) {
								const planOptions = benefitInCategory.options.filter((opt: any) => opt[benefit.plan_name]);

								if (planOptions.length > 1) {
									const optionIndex = planOptions.findIndex((opt: any) => {
										return opt[benefit.plan_name] && opt[benefit.plan_name].description === benefit.description;
									});

									if (optionIndex !== -1) {
										initialSelectedBenefits[benefit.id] = optionIndex;
										console.log(benefit.id);
										console.log(optionIndex);
									}
								}
							}
						}
					});

					default_role.benefits = newBenefits;
					console.log("benefits", default_role.benefits);
					setSelectedBenefits(initialSelectedBenefits);
				}
				else {
					const newBenefits: any[] = [];
					r.benefits.forEach((benefit: any) => {
						newBenefits.push(benefit.id);
					});

					default_role.benefits = newBenefits;
				}
			}
		});
	}, [request_id]);

	async function ValidateForm() {
		console.log(resourceRequests[0].roleOrganization);
		if (ValidateRoles(roles, setRoles)) {
			setLoading(true);
			const token = await LocalStorageManager.getToken();
			const result = await ComunaStaffAPI.UpdateResourceRequest(token, roles[0].id, roles[0]);

			if (result) {
				setSuccess(true);
				window.scrollTo(0, 0);
			} else {
				alert("There was an error sending the request");
			}
			setLoading(false);
		}
	}

	async function DeleteRequest() {
		setLoading(true);
		const token = await LocalStorageManager.getToken();
		const result = await ComunaAPI.softDeleteResourceRequest(
			token,
			roles[0].id
		);
		if (result) {
			window.location.href = "/hiring";
		} else {
			alert("There was an error deleting the request");
		}
	}

	const handlePlanSelect = (plan: string, benefits: number[]) => {
		setSelectedPlan(plan);

		const newRoles = [...roles];
		newRoles[0].benefits = benefits;
		setRoles(newRoles);

		console.log("Selected Plan and Benefits: ", plan, benefits);
	};

	if (!roles || roles.length === 0) {
		return <div></div>;
	}

	return (
		<div className="comuna-popup">
			<div
				className="bg-white rounded-xl p-12 relative fade-in"
				style={{ maxWidth: 1014, width: "90%" }}>
				{/** Close Button */}
				<button
					onClick={() => {
						setConfirmleave(true);
						window.scrollTo(0, 0);
					}}
					className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					<img src={xIcon} alt="icon of an x" />
				</button>

				<h1 className="text-3xl font-bold w-full mb-8">Edit Request</h1>

				<div className="flex flex-row justify-between">
					{step === 1 && (
						<div>
							<p className="font-bold text-lg text-black">Role Details</p>
							<p className="mt-2 text-black">What will they be doing</p>
						</div>
					)}

					{step === 2 && (
						<div>
							<p className="font-bold text-lg text-black">Benefits</p>
							<p className="mt-2 text-black">What will they be getting</p>
						</div>
					)}

					{step === 1 && (
						<button
							className="comuna-secondary-button sm flex flex-row"
							onClick={() => {
								setStep(2);
							}}>
							<span>Go to benefits</span>
							<img
								src={rightArrow}
								alt=""
								style={{ width: 27, height: 27, marginTop: 3, marginLeft: 6 }}
							/>
						</button>
					)}

					{step === 2 && (
						<button
							className="comuna-secondary-button sm flex flex-row"
							onClick={() => {
								setStep(1);
							}}>
							<img
								src={rightArrow}
								alt=""
								style={{
									width: 27,
									height: 27,
									marginTop: 3,
									marginRight: 6,
									transform: "scale(-1,1)",
								}}
							/>
							<span>Back to role details</span>
						</button>
					)}
				</div>
				<div className="flex flex-row mt-4 mb-2">
					<div className={"comuna-step-indicator completed"}></div>
					<div
						className={
							"comuna-step-indicator mx-4 " + (step == 2 ? "completed" : "")
						}></div>
				</div>

				{step === 1 && (
					<RoleForm
						editing={true}
						roles={roles}
						setRoles={setRoles}
						skills={availableSkills}
						labels={availableLabels}
						roleRecs={roleRecs}
					/>
				)}

				{step === 2 && (
					<PricingTable
						planSelected={selectedPlan}
						categories={benefits_plans}
						onPlanSelect={(plan: any, selectedBenefits: any) => {
							setSelectedPlan(plan);
							const newRoles = [...roles];
							newRoles[0].benefits = selectedBenefits;
							setRoles(newRoles);
							console.log("Plan ", plan);
							console.log("Benefits ", selectedBenefits);
						}}
						initialSelectedBenefits={selectedBenefits}
					/>
				)}

				<div className="flex flex-row mt-8">
					<button
						disabled={loading}
						className="comuna-secondary-button font-medium mx-4"
						onClick={() => {
							setConfirmleave(true);
							window.scrollTo(0, 0);
						}}>
						Cancel
					</button>

					<button
						disabled={loading}
						className="comuna-primary-button font-medium"
						onClick={ValidateForm}>
						{loading ? <LoaderSpinner /> : <span>Save Changes</span>}
					</button>
				</div>
			</div>

			{success &&
				createPortal(
					<div className="comuna-popup">
						<div
							className="bg-white rounded-xl p-12 mt-24 relative fade-in text-center"
							style={{ maxWidth: 512, width: "90%" }}>
							<h1 className="text-3xl font-bold w-full mb-8">
								Request updated
							</h1>
							<p className="text-black">Your request has been updated</p>
							<a
								className="comuna-primary-button font-medium mt-8"
								href={"/requests/" + roles[0].id + "/detail"}>
								Ok
							</a>
						</div>
					</div>,
					document.body
				)}

			{confirmLeave &&
				createPortal(
					<div className="comuna-popup">
						<div
							className="bg-white rounded-xl p-12 mt-24 relative fade-in text-center"
							style={{ maxWidth: 512, width: "90%" }}>
							<h1 className="text-3xl font-bold w-full mb-8">Leave page?</h1>
							<p className="text-black">Changes you made will not be saved.</p>
							<button
								className="comuna-primary-button font-medium mt-8"
								onClick={() => {
									window.history.back();
								}}>
								Leave
							</button>
							<button
								className="comuna-secondary-button font-medium mt-4"
								onClick={() => {
									setConfirmleave(false);
								}}>
								Cancel
							</button>
						</div>
					</div>,
					document.body
				)}

		</div>
	);
}
