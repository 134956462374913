//this class allows the user to input a country and then displays the country's information
import { useEffect, useState } from 'react';
import Select from 'react-select'
import gistfile from "../gistfile.json";
import countryCodes from "../country_codes.json";

interface CountryInputProps {
    onChange : (value:any)=>void;
    initialValue?: string;
    disabled?: boolean;
    error?:boolean;
}

interface StateInputProps {
    country: string;
    onChange : (value:any)=>void;
}

const CountryOptions = [
    { value: 'Select Country', label: 'Select Country' }
]

for (let index = 0; index < gistfile.countries.length; index++) {
    const element = {
        value: gistfile.countries[index].country,
        label: gistfile.countries[index].country,
    };
    CountryOptions.push(element);
}

const countryCodeOptions = [
    { value: 'Select', label: 'Select' }
]

for (let index = 0; index < countryCodes.length; index++) {
    const element = {
        value: countryCodes[index].dial_code,
        label: countryCodes[index].name
    };
    countryCodeOptions.push(element);
}


const CountryInput: React.FC<CountryInputProps> = ({onChange, initialValue, disabled=false, error}) => {

    const [selected, setSelected] = useState<any>(CountryOptions[0]);

    useEffect(()=>{
        if(initialValue !== ""){
            setSelected({label:initialValue, value:initialValue});
        }
    }, [initialValue])

    return <Select 
        isDisabled={disabled}
        className='comuna-select w-full' 
        classNames={{
            control: (state)=>error?"comuna-error":"",
            menuList:(state)=>"bg-white"
        }}
        options={CountryOptions} 
        value={selected}
        onChange={(e)=>{
            setSelected(e);
            onChange(e.value);
        }}/>
}

const StateInput: React.FC<StateInputProps> = ({country, onChange}) => {
    
    const options:any[] = [{
        value: "Select State",
        label: "Select State",
    }];
    for (let index = 0; index < gistfile.countries.length; index++) {
        if (gistfile.countries[index].country === country) {
            for (
                let subIndex = 0;
                subIndex < gistfile.countries[index].states.length;
                subIndex++
            ) {
                const element = {
                    value: gistfile.countries[index].states[subIndex],
                    label: gistfile.countries[index].states[subIndex],
                };
                options.push(element);
            }
        }
    }
    
    const [selected, setSelected] = useState<any>(options[0]);

    return <Select 
        className='comuna-select w-full' 
        classNames={{
            menuList:(state)=>"bg-white"
        }}
        options={options} 
        value={selected}
        onChange={(e)=>{
            setSelected(e);
            onChange(e.value);
        }}/>
}

const CountryCodeInput: React.FC<CountryInputProps> = ({onChange, initialValue, disabled, error}) => {

    const [selected, setSelected] = useState<any>(countryCodeOptions[0]);

    useEffect(()=>{
        if(initialValue !== ""){
            setSelected({label:initialValue, value:initialValue});
        }
    }, [initialValue])


    return <Select 
        isDisabled={disabled}
        className='comuna-select w-full' 
        classNames={{
            control: (state)=>error?"comuna-error":"",
            menuList:(state)=>"bg-white"
        }}
        options={countryCodeOptions} 
        value={selected}
        onChange={(e)=>{
            setSelected(e);
            onChange(e.value);
        }}/>
}

export  {CountryInput, StateInput, CountryCodeInput};