import { useContext, useEffect, useState } from "react";
import { useLoaderData, useParams, useSearchParams } from "react-router-dom";
import { setTimeout } from "timers";
import ContractorItem from "../components/MessageViewComponents/ContractorItem";
import MessagesComponent from "../components/MessageViewComponents/MessagesComponent";
import MessageViewInnerTopBar from "../components/MessageViewComponents/MessageViewInnerTopBar";
import ResourceRequestItem from "../components/MessageViewComponents/ResourceRequestItem";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { UserContext } from "../Providers/UserContext";
import Fuse from "fuse.js";
import InterviewsComponent from "../components/MessageViewComponents/InterviewsComponent";
import ApplicationDetailsComponent from "../components/MessageViewComponents/ApplicationDetailsComponent";
import OfferComponent from "../components/MessageViewComponents/OfferComponent";

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	var resReqs = await ComunaAPI.getResourceRequestForStaff(token);
	return { token, resReqs };
}

export function MessagesView() {
	const [searchParams, setSearchParams] = useSearchParams();

	const [requestParam, setRequestParam] = useState<string | null>(null);
	const [appParam, setAppParam] = useState<string | null>(null);
	const [windowParam, setWindowParam] = useState<string | null>(null);

	const unreadIcon = require("../img/icons/unreadIcon.png");
	const searchIcon = require("../img/icons/search-icon.png");

	const { token, resReqs }: any = useLoaderData();

	const { user } = useContext<any>(UserContext);

	const [curatedResReqs, setCuratedResReqs] = useState<any>([]);

	const [selectedResReqId, setSelectedResReqId] = useState<number>(0);
	const [detailedResReq, setDetailedResReq] = useState<any>(null);
	const [oldDetailedResReq, setOldDetailedResReq] = useState<any>(null);
	const [selectedApplication, setSelectedApplication] = useState<any>(null);
	const [selectedWindow, setSelectedWindow] = useState<string>("");

	const [filteredRequests, setFilteredRequests] = useState<any>([]);
	const [filteredApplications, setFilteredApplications] = useState<any>([]);

	const [tempNotifications, setTempNotifications] = useState<any>([
		{
			id: 6,
			event: "new_message",
			target: 63,
			cleared: false,
			date_created: "2023-12-06T17:27:35.456505Z",
			updated_at: "2023-12-06T17:27:43.976622Z",
			counter: 2,
		},
	]);

	const fuseOptions = {
		keys: ["role_name", "organization_profile.name", "comuna_agent.name"],
		includeScore: true,
	};

	const [query, setQuery] = useState(""); //search query

	const [loopCount, setLoopCount] = useState<number>(0);

	// useEffect(() => {
	// 	console.log("CHANGED curatedResReqs: ", curatedResReqs);
	// }, [curatedResReqs]);

	useEffect(() => {
		if (searchParams.get("request") !== null) {
			console.log("searchParams.get('request')", searchParams.get("request"));
			setRequestParam(searchParams.get("request"));
		}

		if (searchParams.get("app") !== null) {
			console.log("searchParams.get('app')", searchParams.get("app"));
			setAppParam(searchParams.get("app"));
		}

		if (searchParams.get("window") !== null) {
			console.log("searchParams.get('window')", searchParams.get("window"));
			setWindowParam(searchParams.get("window"));
		} else {
			setWindowParam("chat");
		}

		var myResReqs: any[] = [];

		resReqs.forEach((rr: any) => {
			if (rr.comuna_agent !== null && user.username === rr.comuna_agent.email) {
				myResReqs.push(rr);
			}
		});
		console.log("curatedResreqs: ", myResReqs);
		setCuratedResReqs(myResReqs);
		setFilteredRequests(myResReqs);
	}, []);

	useEffect(() => {
		// choosing first one by default
		if (requestParam === null) {
			selectFirstResReq();
		}
	}, [curatedResReqs]);

	useEffect(() => {
		//STEP 1 check if there's a request param
		console.log("requestParam: ", requestParam);
		if (requestParam !== null) {
			selectResReqById(requestParam);
		}
	}, [requestParam]);

	function selectResReqById(id: string) {
		//STEP 2 select the request
		console.log("selectResReqById: ", id);
		var resReq = curatedResReqs.find((rr: any) => rr.id === parseInt(id));
		console.log("resReq: ", resReq);
		if (resReq !== undefined) {
			selectResReq(resReq);
		}
	}

	function selectResReq(selectedResReq: any) {
		console.log("selectedResReq:", selectedResReq);
		setSelectedResReqId(selectedResReq.id);
		setSelectedApplication(null);
		GetDetailedResReq(selectedResReq.id);
	}

	async function GetDetailedResReq(id: number) {
		var resReq = await ComunaAPI.getStaffResourceRequestById(token, id);
		// console.log("detailed resReq", resReq);
		// console.log("detailed resReq applications: " + resReq.applications.length);
		setDetailedResReq(resReq);
		FilterApplications(resReq);
	}

	function FilterApplications(resReq: any) {
		var filtered: any[] = [];
		if (resReq === null) {
			return;
		}
		resReq.applications.forEach((app: any) => {
			filtered.push(app);
		});
		setFilteredApplications(filtered);
	}

	useEffect(() => {
		if (JSON.stringify(detailedResReq) !== JSON.stringify(oldDetailedResReq)) {
			console.log(
				"detailedResReq has new value",
				detailedResReq,
				oldDetailedResReq
			);
			// console.log("detailedResReq updated: ", detailedResReq);
			//STEP 3 wait for detailed request and check if there's an application param
			if (detailedResReq !== null && appParam !== null) {
				selectApplicationById(appParam);
			} else {
				//just select the first application
				if (detailedResReq !== null && detailedResReq.applications.length > 0) {
					setSelectedApplication(detailedResReq.applications[0]);
				}
			}
			setOldDetailedResReq(detailedResReq);
		} else {
			console.log("detailedResReq has Old value, chill");
		}
	}, [detailedResReq]);

	function selectApplicationById(id: string) {
		//STEP 4 select the application
		console.log("selectApplicationById: ", id);
		if (detailedResReq.applications === null) return;
		var app = detailedResReq.applications.find(
			(a: any) => a.id === parseInt(id)
		);
		console.log("app: ", app);
		if (app !== undefined) {
			selectApplication(app);
		}
	}

	function selectApplication(newSelectedApplication: any) {
		console.log(
			"selectedApplication:",
			newSelectedApplication.id,
			newSelectedApplication
		);

		setSelectedApplication(newSelectedApplication);
		updateApplication(newSelectedApplication);
	}

	function updateApplication(newSelectedApplication: any) {
		GetDetailedResReq(detailedResReq.id);
		messagesUpToDate();
	}

	function messagesUpToDate() {
		//clear notifications on currently selected application
		console.log("setting messages up to date");

		tempNotifications.forEach((notif: any) => {
			if (
				selectedApplication !== null &&
				notif.target === selectedApplication.id
			) {
				// console.log("trying to clear notifications, id: ", notif.id);
				ClearNotificationsById(notif.id);
			}
		});
	}

	useEffect(() => {
		if (
			selectedApplication !== null &&
			detailedResReq !== null &&
			windowParam !== null
		) {
			setSelectedWindow(windowParam);
		}
	}, [selectedApplication]);

	function updateRequestParams(name: string, value: string) {
		console.log("updateRequestParams: ", name, value);
		console.log("old value: " + name, searchParams.get(name));
		if (searchParams.get(name) !== value) {
			searchParams.set(name, value);
			if (name === "request") {
				searchParams.delete("app");
				searchParams.delete("window");
			}
			if (name === "app") {
				searchParams.delete("window");
			}
			window.location.href =
				window.location.origin + "/messages?" + searchParams.toString();
		}
	}

	function selectFirstResReq() {
		console.log("no param, selecting first from curated: ", curatedResReqs);
		if (curatedResReqs.length > 0) {
			console.log("selected: ", curatedResReqs[0]);
			selectResReq(curatedResReqs[0]);
		}
	}

	useEffect(() => {
		// updates notifications every 3 seconds
		var newLoopValue = 0;
		if (loopCount < 3) {
			newLoopValue = loopCount + 1;
		}

		updateNotifications();

		const timer = window.setTimeout(() => {
			setLoopCount(newLoopValue);
		}, 3000);
		return () => clearTimeout(timer);
	}, [loopCount]);

	async function updateNotifications() {
		var notifications = await ComunaAPI.getStaffNotifications(token);
		// console.log("notifications: ", notifications);
		setTempNotifications(notifications);
	}

	async function ClearNotificationsById(id: number) {
		var noIssues = true;
		try {
			var response = await ComunaAPI.clearStaffNotifications(token, id);
			// console.log("cleared notifications returned: ", response);
			// updateNotifications();
		} catch (error) {
			console.log(error);
			noIssues = false;
		} finally {
			if (noIssues) {
				// console.log("cleared notifications, updating!");
			}
		}
	}

	function SearchQuery(event: any) {
		if (event.key === "Enter") {
			UpdateRequests();
		}
	}

	async function UpdateRequests() {
		const fuse = new Fuse(curatedResReqs, fuseOptions);
		console.log("query: ", query);
		if (query === "") {
			setFilteredRequests(curatedResReqs);
		} else {
			const a = fuse.search(query);
			const filtered: any[] = [];
			a.forEach((item: any) => {
				filtered.push(item.item);
			});
			setFilteredRequests(filtered);
			console.log("filtered: ", filtered);
		}
	}

	return (
		<div className="w-full h-full flex flex-row border-t border-gray-200">
			<div className="LEFTCOL w-2/4 h-full flex flex-col items-center justify-center border-r border-gray-200 bg-white">
				<div className="MESSAGEBAR w-full h-fit flex flex-col py-4 px-8 border-b border-gray-200">
					<div className="w-full h-fit flex flex-row items-center justify-between mb-4">
						<label className="comuna-purple font-bold text-3xl">Messages</label>
						<div className="UNREADBUTTON h-fit w-fit flex flex-row items-center justify-between rounded-lg bg-gray-100 py-1 px-2">
							<img src={unreadIcon} className="w-5 h-5 mr-2" />
							<label className="font-semibold">Unread</label>
						</div>
					</div>
					<div
						className="w-min rounded-3xl border-2 border-gray-300 flex flex-row p-2"
						style={{ width: 624 }}>
						<img
							src={searchIcon}
							style={{ width: 27 }}
							alt="magnifying glass icon"
						/>
						<input
							onChange={(event) => setQuery(event.target.value)}
							onKeyDown={SearchQuery}
							className="w-full"
							type="text"
							placeholder="Search by organization name, role name, etc.."
						/>
					</div>
				</div>
				<div className="RESREQLIST h-full w-full flex flex-col items-center justify-start overflow-scroll">
					{filteredRequests !== null ? (
						<div className="h-full w-full flex flex-col items-center justify-start overflow-scroll">
							{filteredRequests.map((resreq: any, index: number) => {
								return (
									<ResourceRequestItem
										key={index}
										resourceRequest={resreq}
										selected={resreq.id === selectedResReqId}
										onSelectResReq={() => {
											updateRequestParams("request", resreq.id + "");
										}}
										notifications={tempNotifications}
									/>
								);
							})}
						</div>
					) : (
						<label className="w-full h-full p-5 rounded-full text-black ">
							No Resource Requests assigned
						</label>
					)}
				</div>
			</div>
			<div className="MIDDLECOL w-3/5 h-full flex flex-col items-center justify-start border-r border-gray-200 bg-white p-3">
				{filteredApplications !== null && filteredApplications.length > 0 ? (
					<>
						{filteredApplications.map((application: any, index: number) => (
							<ContractorItem
								key={index}
								application={application}
								selected={
									selectedApplication !== null &&
									application.id === selectedApplication.id
								}
								onSelectApplication={() => {
									updateRequestParams("app", application.id + "");
								}}
								notifications={tempNotifications}
							/>
						))}
					</>
				) : (
					<div className="w-full h-full flex flex-row justify-center items-center">
						<p className="text-gray-400 text-xl">No applications available</p>
					</div>
				)}
			</div>
			<div className="RIGHTCOL w-full h-full flex flex-col items-start bg-gray-100">
				{selectedApplication !== null && (
					<div className="w-full h-full flex flex-col items-center justify-start">
						<MessageViewInnerTopBar
							application={selectedApplication}
							window={windowParam === null ? "chat" : windowParam}
							onSelect={(selectedWindow: string) => {
								updateRequestParams("window", selectedWindow);
							}}
						/>
						<div className="w-full h-full flex flex-col justify-start p-4">
							{windowParam !== null && windowParam === "chat" && (
								<MessagesComponent
									application={selectedApplication}
									updatingApplication={selectedApplication}
									upToDate={messagesUpToDate}
									reloadApplication={() => {
										selectApplication(selectedApplication);
									}}
								/>
							)}
							{windowParam !== null && windowParam === "interviews" && (
								<InterviewsComponent application={selectedApplication} />
							)}
							{windowParam !== null && windowParam === "offer" && (
								<OfferComponent
									application={selectedApplication}
									resourceRequest={detailedResReq}
								/>
							)}
							{windowParam !== null && windowParam === "details" && (
								<ApplicationDetailsComponent
									application={selectedApplication}
									resourceRequest={detailedResReq}
								/>
							)}
							{/* <OfferComponent
								application={selectedApplication}
								resourceRequest={detailedResReq}
							/> */}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
