import { useEffect, useState } from "react";

interface InterviewItemProps {
	interviewInfo: string;
	interviewState: string;
}

const InterviewItem: React.FC<InterviewItemProps> = ({
	interviewInfo,
	interviewState,
}) => {
	const bgColors = {
		//items are missed, completed, invite_sent, scheduled
		missed: {
			bgClassName:
				"w-full h-fit flex flex-row rounded-xl p-4 justify-stretch bg-gray-200 mb-4 justify-evenly",
			stateClassName:
				"rounded-lg h-fit w-3/12 flex flex-row bg-gray-300 py-1 px-2 items-center justify-evenly",
		},
		scheduled: {
			bgClassName:
				"w-full h-fit flex flex-row rounded-xl p-4 justify-stretch bg-white  mb-4   justify-evenly items-center",
			stateClassName:
				"rounded-lg h-fit w-3/12 flex flex-row bg-comuna-green py-1 px-2 items-center justify-evenly",
		},
		inviteSent: {
			bgClassName:
				"w-full h-fit flex flex-row rounded-xl p-4 justify- bg-white  mb-4 justify-evenly items-center",
			stateClassName:
				"rounded-lg h-fit w-4/12 flex flex-row bg-orange-400 py-1 px-2 items-center justify-evenly",
		},
	};

	const [itemColors, setItemColors] = useState<any>(bgColors.missed);

	const [stateString, setStateString] = useState<string>("");

	useEffect(() => {
		switch (interviewState) {
			case "missed":
				setItemColors(bgColors.missed);
				setStateString("Missed");
				break;
			case "scheduled":
				setItemColors(bgColors.scheduled);
				setStateString("Scheduled");
				break;
			case "inviteSent":
				setItemColors(bgColors.inviteSent);
				setStateString("Invite Sent");
				break;
			default:
				setItemColors(bgColors.missed);
				setStateString("Missed");
				break;
		}
	}, []);

	return (
		<div className={itemColors.bgClassName}>
			<label className="w-full comuna-purple font-bold">{interviewInfo}</label>
			<div className={itemColors.stateClassName}>
				<div className="rounded-full bg-white w-2 h-2 mr-2" />
				<label className="text-white text-xs">{stateString}</label>
			</div>
		</div>
	);
};

export default InterviewItem;
