import { Link } from "react-router-dom";
import { FormatLink } from "../utils/formaters";
import { useParams, useRouteLoaderData } from "react-router-dom";

import { useState } from "react";

import ApplicationInterviewForm from "./ApplicationInterviewForm";
import { copyToClipBoard } from "../utils/clipboard";

const text_bubble_icon = require("../img/icons/message-text-square-01.png");
const coins_stacked_icon = require("../img/icons/coins-stacked-02.png");
const linkedin_icon = require("../img/icons/linkedin-icon.png");
const pie_chart_icon = require("../img/icons/pie-chart-purple.png");
const receipt_icon = require("../img/icons/receipt-purple.png");
const sliders_icon = require("../img/icons/sliders-03.png");
const coins_hand_icon = require("../img/icons/coins-hand-green.png");
const web_icon = require("../img/icons/web-icon.png");
const hot_candidate_icon = require("../img/icons/hot_candidate.png");
const resume_icon = require("../img/icons/resume-icon.png");
const clock_icon = require("../img/icons/clock-icon.png");
const pencilIcon = require("../img/icons/pencil.png");
const juniorBadge = require("../img/icons/junior-badge.png");
const midBadge = require("../img/icons/mid-badge.png");
const seniorBadge = require("../img/icons/senior-badge.png");
const leadBadge = require("../img/icons/lead-badge.png");
const copyIcon = require("../img/icons/copy.png");

interface ApplicationProfileTabProps {
	application: any;
	contractor: any;
}

function ApplicationProfileTab({ application, contractor }: ApplicationProfileTabProps) {

	const [monthlyBenefitsCost, setMonthlyBenefitsCost] = useState(0);

	const { application_id }: any = useParams();

	const { resource_request_source }: any = useRouteLoaderData(
		"resource_request_detail"
	);

	const GetOneTimeBenefitsCost = () => {
		var total = 0;
		resource_request_source.benefits.map((benefit: any) => {
			application.proposal.benefits.map((subBenefit: any) => {
				if (benefit.id === subBenefit.id && benefit.one_time_only) {
					total += benefit.cost;
				}
			});
		});
		return total;
	};

	const GetMonthlyBenefitsCost = () => {
		if (application.proposal == null) return 0;

		var total = 0;
		resource_request_source.benefits.map((benefit: any) => {
			application.proposal.benefits.map((subBenefit: any) => {
				if (subBenefit.id === benefit.id && !benefit.one_time_only) {
					console.log("found ", benefit.id);
					total += benefit.cost;
				}
			});
		});
		return total;
	};

	//this should be a dynamic percentage loaded from the backend?
	const GetPlatformFee = () => {
		const benefits = GetOneTimeBenefitsCost() + monthlyBenefitsCost * 12;
		const total = benefits + application.proposal.yearly_salary;
		const fee = total * 0.2; //20%
		return fee;
	};

	//we are assuming a 2.5% fee of platform
	const GetGatewayFee = () => {
		const benefits = GetOneTimeBenefitsCost() + monthlyBenefitsCost * 12;
		const comuna_fee = GetPlatformFee();
		const total = benefits + application.proposal.yearly_salary + comuna_fee;
		var gateway_fee = total * 0.029 + 0.3;
		return gateway_fee;
	};

	//return the yearly contract cost.
	function GetTotalContractCost() {
		const benefits = GetOneTimeBenefitsCost() + monthlyBenefitsCost * 12;
		const comuna_fee = GetPlatformFee();
		const gateway_fee = GetGatewayFee(); //gateway fee is not gonna be show at this stage
		const total = benefits + application.proposal.yearly_salary + comuna_fee;
		return total;
	}

	function StateBadge(): any {
		// Ensure the application object is not null or undefined
		if (application !== null && application !== undefined) {

			// Check for 'Hired' state based on the proposal
			if (application.proposal != null && application.proposal.state === 1) {
				return <div className="green-badge big">Hired</div>;
			}

			// Check for 'Rejected' state based on the proposal or general application state
			if ((application.proposal !== null && (application.proposal.state === 2 || application.proposal.state === 3))
				|| (application.proposal === null && application.state === 6)) {
				return <div className="red-badge big">Rejected</div>;
			}

			// Screening process state
			if (application.state === 0) {
				return <div className="blue-badge big mr-2">Screening Candidate</div>;
			}

			// Interview process states
			if (application.state === 1) {
				return <div className="orange-badge big mr-2">Cultural Fit Interview</div>;
			}
			if (application.state === 2) {
				return <div className="yellow-badge big mr-2">Technical Interview</div>;
			}
			if (application.state === 3) {
				return <div className="purple-badge big mr-2">Partner Interview</div>;
			}

			// Offer sent state
			if (application.state === 4 ||
				(application.proposal !== null && application.proposal.state === 0)) {
				return <div className="blue-badge big mr-2">Offer Sent</div>;
			}

			// Final hiring state
			if (application.state === 5) {
				return <div className="green-badge big mr-2">Hired</div>;
			}

			// Final rejection state
			if (application.state === 6) {
				return <div className="red-badge big mr-2">Rejected</div>;
			}
		}
	}

	function InterviewBadge() {
		if (application !== null && application !== undefined) {
			//if hired
			const proposalSent = application.state >= 4;

			//if is past the cultural interview stage
			const canAskForInterview =
				application.state > 1 && application.state !== 5;
			//was the interview requested?
			const interviewRequested =
				application.interview_available_dates != null ||
				application.interview_calendar_link != null;
			//is the interview scheduled?
			const interviewScheduled = application.interview_call_link != null;

			if (interviewScheduled) {
				return (
					<div className="blue-badge big flex flex-row mr-2 items-center">
						<img
							style={{
								width: 16,
								height: 16,
								bottom: 10,
								right: -10,
							}}
							src={clock_icon}
							alt="Fire icon"
							className="mr-1"
						/>
						Interview scheduled
					</div>
				);
			}

			if (interviewRequested) {
				return (
					<div className="blue-badge big flex flex-row mr-2 items-center">
						<img
							style={{
								width: 16,
								height: 16,
								bottom: 10,
								right: -10,
							}}
							src={clock_icon}
							alt="Fire icon"
							className="mr-1"
						/>
						Interview requested
					</div>
				);
			}
		}

		return <></>;
	}

	function Skills() {
		return (
			<div className="flex flex-row flex-wrap mt-2">
				{contractor.skills !== undefined &&
					contractor.skills.map((skill: any, i: number) => {
						return (
							<span key={i} className="skill-badge tooltip mb-2 mr-2">
								{skill}
							</span>
						);
					})}
			</div>
		);
	}

	return (
		<div className="pt-6">
			{application !== undefined && application !== null && (
				<div className="text-left">
					<ApplicationInterviewForm application={application} />
				</div>
			)}

			{/**Contractor Information */}
			<div className="flex flex-row justify-between items-center bg-purple-light mb-3 rounded-lg p-6 mt-8">
				<div className="flex flex-row items-center">
					{/**Profile Picture */}
					<div className="relative">
						{contractor.profile_picture && (
							<img
								src={contractor.profile_picture}
								alt=""
								style={{ height: 128, width: 128, borderRadius: "50%" }}
							/>
						)}
						{!contractor.profile_picture && (
							<div
								className="bg-comuna-purple flex justify-center items-center font-bold text-white text-4xl"
								style={{
									height: 128,
									width: 128,
									borderRadius: "50%",
									lineHeight: -10,
								}}>
								{contractor.first_name.charAt(0).toUpperCase() +
									contractor.last_name.charAt(0).toUpperCase()}
							</div>
						)}
					</div>

					{/**Name Title and badges */}
					<div className="flex flex-col ml-4 items-start w-fit">
						<h3 style={{ height: 31 }} className="font-semibold black-text">
							{contractor.first_name + " " + contractor.last_name}
						</h3>
						<span className="mb-2">
							{contractor.title} - {contractor.country_location}
						</span>
						<div className="flex flex-row items-center w-fit">
							<StateBadge />
							<InterviewBadge />
							{contractor.top_candidate && (
								<div className="flex flex-row items-center yellow-badge big">
									<img
										style={{
											width: 16,
											height: 16,
											bottom: 10,
											right: -10,
										}}
										src={hot_candidate_icon}
										alt="Fire icon"
									/>
									Hot Candidate
								</div>
							)}
						</div>
					</div>
				</div>
				{/**Quick Actions Buttons */}
				<div className="flex flex-row items-start justify-end flex-1">
					{contractor.website && contractor.website !== "null" && (
						<a
							onClick={(e) => {
								e.stopPropagation();
							}}
							target="_new"
							className="comuna-circle-button"
							href={FormatLink(contractor.website)}>
							<img
								src={web_icon}
								alt="Button to visit website of the candidate"
							/>
						</a>
					)}

					{contractor.linkedin && contractor.linkedin !== "null" && (
						<a
							onClick={(e) => {
								e.stopPropagation();
							}}
							target="_new"
							className="comuna-circle-button"
							href={FormatLink(contractor.linkedin)}>
							<img
								src={linkedin_icon}
								alt="Button to go to linkedin of the candidate"
							/>
						</a>
					)}

					{contractor.cv && contractor.cv !== "null" && (
						<a
							onClick={(e) => {
								e.stopPropagation();
							}}
							target="_new"
							className="comuna-circle-button"
							href={FormatLink(contractor.cv)}>
							<img src={resume_icon} alt="Button to download resume" />
						</a>
					)}

					<Link
						className="self-center ml-3"
						to={
							"/requests/" +
							resource_request_source.id +
							"/application/" +
							application_id +
							"/edit"
						}>
						<button className="comuna-secondary-button items-center w-fit">
							<img
								src={pencilIcon}
								style={{
									width: 16,
									height: 16,
								}}
							/>
							<label className="ml-2">Edit application</label>
						</button>
					</Link>
				</div>
			</div>

			{/** Experience and language fluency */}
			<div className="flex flex-row mb-3 w-full">
				{contractor.seniority === 1 && (
					<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
						<img
							src={juniorBadge}
							alt="four triangles indicating level"
							style={{ height: 24, marginRight: 12 }}
						/>
						<b>1-3 years of experience</b>
					</div>
				)}

				{contractor.seniority === 2 && (
					<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
						<img
							src={midBadge}
							alt="four triangles indicating level"
							style={{ height: 24, marginRight: 12 }}
						/>
						<b>3-6 years of experience</b>
					</div>
				)}

				{contractor.seniority === 3 && (
					<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
						<img
							src={seniorBadge}
							alt="four triangles indicating level"
							style={{ height: 24, marginRight: 12 }}
						/>
						<b>{"6-10 years of experience"}</b>
					</div>
				)}

				{contractor.seniority === 4 && (
					<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
						<img
							src={leadBadge}
							alt="four triangles indicating level"
							style={{ height: 24, marginRight: 12 }}
						/>
						<b>10+ years of experience</b>
					</div>
				)}

				<div className="bg-purple-light mb-2 rounded-lg p-4  w-full justify-center flex flex-row tooltip">
					<img
						src={text_bubble_icon}
						alt="text bubble icon"
						style={{ width: 24, height: 24, marginRight: 8 }}
					/>
					<b>{"Fluent english"}</b>
					<b className="tooltiptext" style={{ top: "-50%" }}>
						English fluency
					</b>
				</div>
			</div>

			{/** Salary Recommendation and what the candidate wants */}
			{application !== null && application !== undefined && (
				<div className="flex flex-row mb-3 w-full">
					{/* salary Recommendation */}
					<div className="bg-comuna-blue-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-col text-left">
						<img
							src={sliders_icon}
							alt="four triangles indicating level"
							style={{ height: 24, width: 24, marginRight: 12 }}
						/>
						<label className="text-sm">Salary for similar profile in the U.S.</label>
						<b>${application.salary_recommendation.toLocaleString()} </b>
					</div>

					{/* what the candidate wants */}
					<div className="bg-comuna-yellow-light mb-2 rounded-lg p-4 w-full flex flex-col text-left">
						<div className="flex flex-row w-full justify-between">
							<img
								src={coins_stacked_icon}
								alt="four triangles indicating level"
								style={{ height: 24, width: 24, marginRight: 12 }}
							/>
							{contractor.salary_expectation >
								application.salary_recommendation && (
									<div className="pink-badge small text-xs mr-2">
										Overpriced
									</div>
								)}
						</div>
						<label className="text-sm">What the candidate wants</label>
						<b>${contractor.salary_expectation.toLocaleString()} </b>
					</div>
				</div>
			)}

			{/* offer and costs */}
			{application !== null &&
				application !== undefined &&
				application.proposal !== null &&
				application.proposal !== undefined && (
					<div className="flex flex-row mb-3 w-full">
						{/* Offer */}
						<div className="bg-comuna-green-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-col text-left">
							<img
								src={coins_hand_icon}
								alt="four triangles indicating level"
								style={{ height: 24, width: 24, marginRight: 12 }}
							/>
							<label className="text-sm comuna-dark-green-text">
								Your offer (Salary + Benefits)
							</label>
							<b className="comuna-dark-green-text">
								$
								{
									(application.proposal.yearly_salary ?
										(
											application.proposal.yearly_salary +
											GetOneTimeBenefitsCost() +
											monthlyBenefitsCost
										)
											.toLocaleString()
											.split(".")[0]
											: "--,---"
									)
									
								}{" "}
							</b>
						</div>

						{/* what the candidate wants */}
						<div
							className="hover-card bg-purple-light mb-2 rounded-lg p-4 w-full flex flex-col text-left"
							onClick={() => {
								// setSeeCosts(true);
							}}>
							<div className="flex flex-row w-full justify-between">
								<img
									src={receipt_icon}
									alt="four triangles indicating level"
									style={{ height: 24, width: 24, marginRight: 12 }}
								/>
								<img
									src={pie_chart_icon}
									alt="four triangles indicating level"
									style={{ height: 24, width: 24, marginRight: 12 }}
								/>
							</div>
							<label className="text-sm comuna-purple-text">
								Final cost (Salary + Benefits + Taxes + Comuna fee)
							</label>
							<b className="comuna-purple-text">
								${
								application.proposal.yearly_salary ?
								GetTotalContractCost().toLocaleString().split(".")[0] : "--,---"}{" "}
							</b>
						</div>
					</div>
				)}


			<div className="w-full flex flex-col py-2 text-left">
				<b className="text-sm font-semibold">Skills</b>
				<Skills />
			</div>

			<p className="text-sm font-semibold text-left black-text mb-2">Contact information</p>

			<div className="flex flex-row bg-purple-light justify-between mb-4 p-4 rounded-lg">
				<p className="black-text">Email</p>
				<div className="flex flex-row items-center">
					<p id="contractor_email" className="font-semibold  black-text mr-2">{contractor.email}</p>
					<button className="focus:opacity-5" onClick={() => { copyToClipBoard(contractor.email) }}><img style={{ width: 24, height: 24 }} src={copyIcon} alt="" /></button>

				</div>
			</div>

			<div className="flex flex-row bg-purple-light justify-between mb-4 p-4 rounded-lg">
				<p className="black-text">Phone</p>
				<div className="flex flex-row items-center">
					<p id="contractor_phone" className="font-semibold  black-text mr-2">{contractor.phone_country_indicator + " " + contractor.phone_number}</p>
					<button className="focus:opacity-5" onClick={() => { copyToClipBoard(contractor.phone_country_indicator + " " + contractor.phone_number) }}><img style={{ width: 24, height: 24 }} src={copyIcon} alt="" /></button>
				</div>
			</div>

			{/** Notes from Comuna */}
			<div className="flex flex-col mb-3 w-full mt-5 text-left">
				<b className="text-sm font-semibold">Notes from comuna</b>
				<div className="bg-purple-light mb-2 mt-2 rounded-lg p-4 mr-4 w-full flex flex-col">
					<p className="text-left black-text">
						{application !== null && application.contractor.note_for_client}
					</p>
				</div>
			</div>

			{/** Internal Notes */}
			<div className="flex flex-col pb-5 mb-3 w-full mt-5 text-left">
				<b className="text-sm font-semibold">Internal Notes</b>
				<div className="bg-purple-light mb-2 mt-2 rounded-lg p-4 mr-4 w-full flex flex-col">
					<p className="text-left black-text">
						{application !== null && application.internal_notes}
					</p>
				</div>
			</div>
		</div>)
}

export default ApplicationProfileTab;