import { Link, NavLink, Outlet, useParams, useNavigate } from "react-router-dom";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";

export function DeleteLabelPopup(){
	const closeIcon = require("../img/icons/x.png");

	const { label_id }: any = useParams();
    const navigate  = useNavigate ();

    const HandleDeleteLabel = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
    
        const token = LocalStorageManager.getToken();
        try {
            await ComunaStaffAPI.DeleteLabel(token, label_id);
            navigate("/requests/labels", { state: { refresh: true } });
        } catch (error) {
            alert("Failed to delete label: " + error);
        }
    };

    return(
        <div className="comuna-popup">
            <div
				className="comuna-card padding relative"
				style={{
					overflow: "initial",
					width: "55%",
					maxWidth: 1500,
					minWidth: 800,
				}}>
				<Link
					to={"/requests/labels/"}
					style={{ position: "absolute", top: 20, right: 20 }}>
					<img src={closeIcon} style={{ width: 24, height: "auto" }} alt="" />
				</Link>

                <h2 className="text-left py-3 my-3 w-full text-center">Delete internal label?</h2>

                <p className="px-6">If you proceed, this internal label will be permanently deleted from any requests and applications with it.</p>

                <div className="flex flex-row mt-6">
                    <NavLink className='flex-1 comuna-red-alt px-6 py-3 mx-3 rounded-full' onClick={HandleDeleteLabel} to={"/requests/labels/"} >Delete</NavLink>
                    <NavLink className='flex-1 comuna-purple-bold px-6 py-3 mx-3 rounded-full' to={"/requests/labels/"}>Cancel</NavLink>
                </div>
            </div>
            <Outlet/>
        </div>
    )
}
