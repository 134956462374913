
interface ConfirmPopupProps{
    title: string;
    message: string;
    action: string;
    onConfirm: ()=>void;
    onCancel: ()=>void;

}

const ConfirmPopup: React.FC<ConfirmPopupProps>  = ({ title, message, onConfirm, onCancel, action }) => {
    const closeIcon = require("../img/icons/x.png");

    return (
        <div className="comuna-popup">
            <div className="comuna-card padding relative" style={{maxWidth:480}}>
                <button onClick={onCancel} style={{position:"absolute",top:20,right:20}}>
                    <img src={closeIcon}
                        style={{ width: 24, height: "auto" }}
                        alt=""
                        />
                </button>
                <h1 className="text-2xl color-black font-bold text-center w-full">{title}</h1>
                <p className="text-center mt-4">{message}</p>
                <button className="comuna-primary-button mt-4" onClick={onConfirm}>{action}</button>
                <button className="comuna-secondary-button mt-4" onClick={onCancel} >Cancel</button>
            </div>
        </div>
    )
}

export default ConfirmPopup;