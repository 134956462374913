import { useEffect, useState } from "react";

export function PurpleCasedItem({ benefit, onDelete, canDelete, big = false }) {
	const purplex = require("../img/icons/x-purple.png");
	function deleteItem() {
		onDelete(benefit.id);
	}

	const textsize = big ? "text-md p-2" : "text-sm";

	return (
		<span className="bg-comuna-purple-transparent flex flex-row comuna-purple p-2 py-1 mr-2 rounded-lg mb-2">
			<b className={textsize + " self-center font-medium"}>{benefit.name}</b>
			{canDelete && (
				<button
					className="comuna-purple self-center font-medium"
					onClick={() => {
						deleteItem();
					}}>
					<img src={purplex} style={{ width: 24 }} alt="" />
				</button>
			)}
		</span>
	);
}
