import { useState, useRef } from "react";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { Link } from "react-router-dom";
import { spawn } from "child_process";

interface RoleApplicationManagerProps {
	resource_request: any;
	set_resource_request: any;
}

const RoleApplicationManager: React.FC<RoleApplicationManagerProps> = ({
	resource_request,
	set_resource_request,
}) => {
	const labelClassName = "block text-xl text-left font-bold mb-2 mt-4 mb-6";

	interface ApplicationItemProps {
		application: any;
	}

	const GetBadgeClass = (score: number) => {
		if (score >= 7) {
			return "mint-badge"; // Green for high scores
		} else if (score > 5) {
			return "yellow-badge"; // Yellow for mid-range scores
		} else {
			return "red-badge"; // Red for low scores
		}
	};

	const ApplicationItem = ({ application }: ApplicationItemProps) => {
		function InterviewState() {
			//did this contractor rejected all sended dates?
			if (application.interview_accepted_date === "rejected") {
				return <span className="red-badge">Interview Rejected</span>;
			}

			//dows this interview have a call link already?
			if (application.interview_call_link?.length > 0) {
				return <span className="blue-badge">Interview Scheduled</span>;
			}

			//the interview was requested but is still pending.
			if (
				application.interview_available_dates?.length > 0 ||
				application.interview_calendar_link?.length > 0
			) {
				return <span className="yellow-badge">Interview Requested</span>;
			}

			if (application.interview && application.interview.application === application.id) {
				const badgeClass = GetBadgeClass(application.interview.score);

				return (
					<div className="flex items-start space-x-2 mt-1 w-full">
						<span className="mint-badge">Interviewed</span>
						{application.interview.score !== undefined && (
							<span
								className={`${badgeClass} relative`}
								style={{
									top: '-3px',
									right: '-20px',
								 }} // Adjust this value to move the score lower
							>
								{application.interview.score.toFixed(1)}
							</span>
						)}
					</div>
				);
			}


			return null;
		}

		return (
			<Link
				to={
					"/requests/" + resource_request.id + "/application/" + application.id
				}>
				<Draggable draggableId={"app-" + application.id} index={application.id}>
					{(provided) => (
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							className="flex flex-row bg-purple-light p-4 rounded-xl mb-2 items-center cursor-grab">
							{application.contractor.profile_picture && (
								<img
									src={application.contractor.profile_picture}
									className="profile-picture"
									alt=""
								/>
							)}
							{!application.contractor.profile_picture && (
								<div className="profile-placeholder">
									{application.contractor.first_name[0]}
									{application.contractor.last_name[0]}
								</div>
							)}

							<div className="flex flex-col items-start ml-2 ">
								<b className="select-none text-left leading-3">
									{application.contractor.first_name +
										" " +
										application.contractor.last_name}
								</b>
								<InterviewState />
							</div>
						</div>
					)}
				</Draggable>
			</Link>
		);
	};

	const ApplicationColumn = ({
		applications,
		state,
		title,
		orMore,
		badgeBG,
		badgeText,
	}: any) => {
		return (
			<div className="comuna-column comuna-column-interactive">
				<label className="text-left font-bold text-lg mb-4">{title}</label>
				<span
					className="count"
					style={{ backgroundColor: badgeBG, color: badgeText }}>
					{applications.length}
				</span>
				<Droppable droppableId={"col-" + state}>
					{(provided) => (
						<div
							className="flex flex-col"
							style={{ minHeight: 400 }}
							{...provided.droppableProps}
							ref={provided.innerRef}>
							{applications.length > 0 &&
								applications.map((application: any) => (
									<ApplicationItem
										application={application}
										key={application.id}
									/>
								))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		);
	};

	const ApplicationColumNotDraggable = ({ applications, title }: any) => {
		return (
			<div className="comuna-column">
				<label className="text-left font-bold text-lg mb-4">{title}</label>
				<span
					className="count"
					style={{ backgroundColor: "#B0E8CF", color: "#07754D" }}>
					{applications.length}
				</span>
				<div className="flex flex-col" style={{ minHeight: 400 }}>
					{applications.map((application: any) => (
						<Link
							to={
								"/requests/" + resource_request.id + "/application/" + application.id
							}>
							<div className="flex flex-row bg-purple-light p-4 rounded-xl mb-2 items-center cursor-grab">
								{application.contractor.profile_picture && (
									<img
										src={application.contractor.profile_picture}
										className="profile-picture"
										alt=""
									/>
								)}
								{!application.contractor.profile_picture && (
									<div className="profile-placeholder">
										{application.contractor.first_name[0]}
										{application.contractor.last_name[0]}
									</div>
								)}
								<div className="flex flex-col w-fit">
									<b className="ml-2 select-none text-left">
										{application.contractor.first_name +
											" " +
											application.contractor.last_name}
									</b>
									{application.state > 3 && (
										<label
											className={
												"text-white text-xs flex-wrap rounded-lg h-fit w-fit p-1 ml-2 bg-gray-500"
											}>
											{application.proposal !== null &&
												application.proposal.state === 0 &&
												"Proposal sent"}
											{application.proposal !== null &&
												application.proposal.state === 1 &&
												"Hired"}
											{application.proposal !== null &&
												(application.proposal.state === 2 ||
													application.proposal.state === 3) &&
												"Rejected"}
											{application.proposal === null &&
												application.state === 6 &&
												"Rejected"}
										</label>
									)}
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		);
	};

	const onDragEnd = async ({ source, destination }: DropResult) => {
		if (destination === undefined) {
			return;
		}
		if (destination === null) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		//remove item from source list a given id
		var sourceList = GetList(source.droppableId);
		sourceList = sourceList.filter((item) => item.id !== source.index);
		setList(source.droppableId, sourceList);

		var destinationList = GetList(destination.droppableId);
		resource_request.applications.forEach((item: any) => {
			if (item.id === source.index) {
				destinationList.push(item);
			}
		});
		setList(destination.droppableId, destinationList);

		//finally we make an API call to update the "state" of the application
		const token = await LocalStorageManager.getToken();
		const result = ComunaStaffAPI.UpdateApplicationState(
			token,
			source.index,
			destination.droppableId[4]
		);
		if (!result) {
			alert("Error updating application state");
			window.location.reload();
		}
		//we also need to update the local version of the resource request
		const newResourceRequest = { ...resource_request };
		newResourceRequest.applications.forEach((item: any) => {
			if (item.id === source.index) {
				item.state = parseInt(destination.droppableId[4]);
			}
		});
		set_resource_request(newResourceRequest);
		source.index = destination.index;
	};

	const GetList = (id: string) => {
		switch (id) {
			case "col-0":
				return [...screeningList];
			case "col-1":
				return [...culturalList];
			case "col-2":
				return [...technicalList];
			case "col-3":
				return [...partnerList];
			default:
				return [];
		}
	};

	const setList = (id: string, newList: any[]) => {
		switch (id) {
			case "col-0":
				setScreeningList(newList);
				break;
			case "col-1":
				setCulturalList(newList);
				break;
			case "col-2":
				setTechnicalList(newList);
				break;
			case "col-3":
				setPartnerList(newList);
				break;
			default:
				return [];
		}
	};

	const [screeningList, setScreeningList] = useState<any[]>(
		resource_request.applications.filter((item: any) => item.state === 0)
	);
	const [culturalList, setCulturalList] = useState<any[]>(
		resource_request.applications.filter((item: any) => item.state === 1)
	);
	const [technicalList, setTechnicalList] = useState<any[]>(
		resource_request.applications.filter((item: any) => item.state === 2)
	);
	const [partnerList, setPartnerList] = useState<any[]>(
		resource_request.applications.filter((item: any) => item.state === 3)
	);
	const [decisionList, setDecisionList] = useState<any[]>(
		resource_request.applications.filter((item: any) => item.state > 3)
	);

	return (
		<div className="flex flex-row min-h-full w-full h-full p-4 text-center">
			<DragDropContext onDragEnd={onDragEnd}>
				<ApplicationColumn
					title={"Screening Candidate"}
					applications={screeningList}
					state={0}
					badgeBG={"#D2E5F9"}
					badgeText={"#0C325A"}
				/>
				<ApplicationColumn
					title={"Cultural fit interview"}
					applications={culturalList}
					state={1}
					badgeBG={"#B8F8FA"}
					badgeText={"#096F72"}
				/>
				<ApplicationColumn
					title={"Technical interview"}
					applications={technicalList}
					state={2}
					badgeBG={"#FFEDD1"}
					badgeText={"#5C3B00"}
				/>
				<ApplicationColumn
					title={"Partner interview"}
					applications={partnerList}
					state={3}
					badgeBG={"#E9D7FF"}
					badgeText={"#390080"}
				/>
			</DragDropContext>
			<ApplicationColumNotDraggable
				title={"Decision"}
				applications={decisionList}
			/>
		</div>
	);
};

export default RoleApplicationManager;
