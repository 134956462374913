export const ENV = "dev"; //local, dev or prod

//dev by default
let BASE_URL = "https://dev.api.thecomuna.com/api/v1/";
let Pic_URL = "https://comuna-core-dev.s3.amazonaws.com";
let ADMIN_URL = "https://dev.api.thecomuna.com/admin/";

if (ENV === "prod") {
	BASE_URL = "https://api.thecomuna.com/api/v1/";
	Pic_URL = "https://comuna-core-prod.s3.amazonaws.com";
	ADMIN_URL = "https://api.thecomuna.com/admin/";
}

if (ENV === "local") {
	BASE_URL = "http://localhost:8000/api/v1/";
	Pic_URL = "http://localhost:8000";
	ADMIN_URL = "http://localhost:8000/admin/";
}

export const stateColor = {
	_0: "text-blue-300 ",
	_1: "text-red-300 ",
	_2: "text-yellow-300 ",
	_3: "text-green-300 ",
	_4: "text-purple-300 ",
	_5: "text-gray-300 ",
	_6: "text-pink-300 ",
};

export const stateColorBG = {
	_0: "bg-screening ",
	_1: "bg-cultural ",
	_2: "bg-technical ",
	_3: "bg-partner ",
	_4: "bg-decision ",
	_5: "bg-gray-200 ",
	_6: "bg-pink-200 ",
};

export { BASE_URL, Pic_URL, ADMIN_URL};

export const ResourceRequestOverviewTemplate = `[Job Title]
We are actively seeking a highly proficient and seasoned [Job Title] to join our dynamic [Company/Team] at [Company Name]. The ideal candidate will possess [summarize some skills] 

Project Information:
As a [Job Title], you'll be working on [Description of projects, goals, and technologies].

**Responsibilities:
- [Responsibilities]

Qualifications:
- [Relevant experience required]

About [Company Name]:
[Provide a brief description of the company, its mission, and culture]`;