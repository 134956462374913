import { request } from "https";
import { useContext, useEffect, useState } from "react";
import { ComunaAPI } from "../../managers/ComunaAPI";
import { LocalStorageManager } from "../../managers/LocalStorageManager";
import { UserContext } from "../../Providers/UserContext";
import { PurpleCasedItem } from "../PurpleCasedItem";

interface ApplicationDetailsComponentProps {
	application: any;
	resourceRequest: any;
}

const ApplicationDetailsComponent: React.FC<
	ApplicationDetailsComponentProps
> = ({ application, resourceRequest }) => {
	const [applicationDetails, setApplicationDetails] = useState<any>({});

	const [seniority, setSeniority] = useState<string>("");

	const token = LocalStorageManager.getToken();

	const [skillDefs, setSkillDefs] = useState<any[]>([]);

	useEffect(() => {
		console.log("application: ", application);
		console.log("resourceRequest: ", resourceRequest);
		checkSeniority();
		getBenefitDefinitions();
	}, []);

	async function getBenefitDefinitions() {
		try {
			const response = await ComunaAPI.getResReqFields(token);
			console.log("proposal fields: ", response);

			setSkillDefs(response.skills);
		} catch (error) {
			console.log(error);
		} finally {
		}
	}

	async function checkSeniority() {
		switch (application.contractor.seniority) {
			case 0:
				setSeniority("Junior");
				break;
			case 1:
				setSeniority("Semi-Senior");
				break;
			case 2:
				setSeniority("Senior");
				break;
			default:
				setSeniority("Senior");
				break;
		}
	}

	return (
		<div className="w-full h-fit flex flex-col bg-white rounded-xl p-4 justify-start">
			<div className="TOP w-full h-fit flex flex-row bg-gray-200 rounded-xl justify-between items-center p-5 mb-5">
				<div className="w-full h-fit flex flex-col">
					<label className="w-full h-fit text-xl font-semibold text-gray-700">
						{resourceRequest.role_name}
					</label>
					<label className="w-full h-fit text-sm text-gray-700 mb-1">
						{application.contractor.country_location}
					</label>
					<div className="w-fit h-fit flex flex-row items-center bg-white rounded-lg p-1">
						<img
							src={application.contractor.picture}
							className="w-5 h-5 rounded-full"
						/>
						<label className="w-full h-fit text-sm text-gray-700 ml-2">
							{seniority}
						</label>
					</div>
				</div>
			</div>
			<div className="MIDDLE w-full h-fit flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 mb-5">
				<label className="w-full h-fit text-sm text-gray-700 text-start">
					Ideal start date
				</label>
				<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
					{application.proposal === null
						? "Not specified"
						: application.proposal.start_date}
				</label>
			</div>
			<div className="w-full h-fit flex flex-row mb-5">
				<div className="w-full h-full flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						Modality
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						{resourceRequest.modality}
					</label>
				</div>
				{/* <div className="w-full h-fit flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 ml-3">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						Prefered locations??
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						No such field
					</label>
				</div> */}
			</div>
			<div className="MIDDLE w-full h-fit flex flex-col justify-stretch items-center mb-5">
				<label className="w-full h-fit text- font-semibold text-gray-700 text-start">
					Skills
				</label>
				<div className="flex flex-row w-full h-fit justify-start items-center">
					{resourceRequest.skills.map((skill: number, i: number) => {
						return (
							<>
								{skillDefs.map((skillDef: any, j: number) => {
									return (
										<>
											{j === skill && (
												<PurpleCasedItem
													key={j}
													benefit={skillDef}
													onDelete={() => {}}
													canDelete={false}
												/>
											)}
										</>
									);
								})}
							</>
						);
					})}
				</div>
			</div>
			<div className="MIDDLE w-full h-fit flex flex-col justify-stretch items-center mb-5">
				<label className="w-full h-fit text-md text-gray-700 font-semibold text-start">
					Role description
				</label>
				<label className="w-full h-fit text-sm text-gray-700 text-start">
					{resourceRequest.position_overview}
				</label>
			</div>
			<div className="MIDDLE w-full h-fit flex flex-col justify-stretch items-center mb-5">
				<label className="w-full h-fit text-md text-gray-700 font-semibold text-start">
					Additional Details
				</label>
				<label className="w-full h-fit text-sm text-gray-700 text-start">
					{resourceRequest.additional_details !== ""
						? "No additional details."
						: resourceRequest.additional_details}
				</label>
			</div>
		</div>
	);
};

export default ApplicationDetailsComponent;
