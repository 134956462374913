import { useRef} from "react";
import { useNavigate } from "react-router-dom";

const xIcon = require("../img/icons/x.png");

interface ComunaPanelInterface{
    children: any, 
    max_width?: number,
    return_to?: string
}

export function ComunaSidePanel({children, max_width, return_to}:ComunaPanelInterface){
    
    const navigate = useNavigate();

    const panelRef = useRef<HTMLDivElement>(null);

    function close(){
        if(return_to!=null){
            navigate(return_to);
        }else{
            navigate(-1);
        }
    }

    return(
    <div>

        <div className="cover" onClick={close}></div>

        <div className="comuna-side-panel open">
            <div 
                className="comuna-card fade-in p-8 w-full text-center relative" 
                style={{maxWidth: max_width?max_width:1024, overflowY:"auto", minHeight:"100vh"}}
                ref={panelRef}>
                <button
						onClick={close}
						style={{ top: 16, right: 16, position: "absolute" }}>
						<img src={xIcon} alt="close icon" style={{ width: 19 }} />
				</button>

                {children}

            </div>
        </div>
    </div>
    )
}