import { DateTime } from "luxon";

export const formatTimestamp = (inputTimestamp: string): string => {
	// Parse the input timestamp string
	const timestamp = new Date(inputTimestamp);

	// Get the date components
	const year = timestamp.getFullYear();
	const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
	const day = timestamp.getDate().toString().padStart(2, "0");

	// Get the time components
	const hours = timestamp.getHours();
	const minutes = timestamp.getMinutes();

	// Convert hours to AM/PM format
	const amPm = hours >= 12 ? "pm" : "am";
	const formattedHours = hours % 12 || 12; // Handle 0 as 12 for noon/midnight

	// Format the time in HH:mm am/pm format
	const timeString = `${formattedHours}:${minutes
		.toString()
		.padStart(2, "0")} ${amPm}`;

	// Combine the date and time components
	const formattedTimestamp = `${timeString} - ${year}-${month}-${day}`;

	return formattedTimestamp;
};

export function formatDateFromString(date: string) {
	var dateObj = new Date(date);
	var month = dateObj.getMonth() + 1;
	var day = dateObj.getDate();
	var year = dateObj.getFullYear();
	var newdate = day + "/" + month + "/" + year;
	return newdate;
}

export function formatAmountCommas(amount: number) {
	const options = { maximumFractionDigits: 2 };
	const formattedNumber = Intl.NumberFormat("en-US", options).format(
		Math.floor(amount)
	);
	return formattedNumber;
}

/** Gets a UTC time string and returns the proper Local time String. */
export function FormatDateToLocalString(date: string): string {
	if(!date)
		return "Not specified";
	const utcDate = DateTime.fromISO(date.replace(" ", "T"), { zone: "utc" });
	const localDate = utcDate.setZone(DateTime.local().zoneName);
	return localDate.toLocaleString(DateTime.DATETIME_MED);
}

export function FormatLink(link: string): string {
	if (link.includes("http://") || link.includes("https://")) {
		return link;
	} else {
		return "https://" + link;
	}
}
