import { ENV  } from "../config";

export function ComunaLogo(){
	const comunaLogo = require("../img/comuna-logo.png");
    return(
        <div className="relative">
            <img src={comunaLogo} className="comuna-logo" alt="comuna logotype" />
            <span 
                style={{
                    fontSize: 15,
                    right: -13,
                    top: -13
                }}
                className="absolute top-0 right-0 bg-green-500 font-bold text-white px-2 rounded-xl">STAFF</span>
            {/* ENV === 'dev' &&  <span className="absolute top-0 right-0 bg-green-500 font-bold text-white px-2 rounded-xl">DEV</span> */}
            {/* ENV === 'local' &&  <span className="absolute top-0 right-0 bg-orange-500 font-bold text-white px-2 rounded-xl">LOC</span> */}
        </div>
    )
}