import { useContext, useState, useEffect } from "react";
import { UserContext } from "../Providers/UserContext";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import Fuse from 'fuse.js'


interface SearchForComunaAgentProps {
    onClose: ()=>void;
    requestId: number;
    roleName: string;
    updateRequests: ()=>void;
}

const fuseOptions = {
	keys: [
		"first_name",
		"last_name",
		"username"
	],
	includeScore: true
}

const SearchForComunaAgent:React.FC<SearchForComunaAgentProps> = ({onClose, requestId, roleName, updateRequests})=>{
	
    const searchIcon = require("../img/icons/search-icon.png");
    const closeIcon = require("../img/icons/x.png");

    const {token} = useContext<any>(UserContext);

    const [query, setQuery] = useState("");
    const [comunaAgents, setComunaAgents] = useState<any[]>([]);
    const [filtered, setFiltered] = useState<any[]>([]);
    const [selectedAgent, setSelectedAgent] = useState<any>(false);
    const [confirm, setConfirm] = useState(false);

    useEffect(()=>{
        LoadAgents();
    },[])

    useEffect(()=>{
        if(query===""){
            setFiltered(comunaAgents);
        }
    },[query])

    async function LoadAgents(){
        //load agents
        const agents = await ComunaStaffAPI.GetComunaAgents(token);
        setComunaAgents(agents);
        setFiltered(agents);
    }

    function SearchQuery(event:any){
		if (event.key === 'Enter') {
            const fuse = new Fuse(comunaAgents, fuseOptions);
            const a = fuse.search(query);
			const filtered_:any[] = [];
			a.forEach((item:any)=>{
				filtered_.push(item.item);
			})
            setFiltered(filtered_);
		}
	}

    async function SubmitForm(){
        const data = await ComunaStaffAPI.AssignComunaAgent(token, selectedAgent.id, requestId);
        if(data){
            updateRequests();
            onClose();
        }else{
            alert("Error assigning agent. Please try again");
        }
    }

    return (
        <div className="comuna-popup">

            {confirm && 
            <div className="comuna-card padding relative" style={{maxWidth:480}}>
                <button onClick={()=>{ setConfirm(false); }} style={{position:"absolute",top:20,right:20}}>
					<img src={closeIcon}
						 style={{ width: 24, height: "auto" }}
						 alt=""
						/>
				</button>
                <h1 className="text-2xl color-black font-bold text-center w-full">Assign agent?</h1>
                <p className="text-center mt-4">Are you sure you want to assign <span className="comuna-purple font-bold">{selectedAgent.first_name+" "+selectedAgent.last_name}</span> to take care of this request?</p>
                <button className="comuna-primary-button mt-4" onClick={SubmitForm}>Assign this agent</button>
                <button className="comuna-secondary-button mt-4" onClick={()=>{ setConfirm(false); }} >Cancel</button>
            </div>}

			{!confirm &&  <div className="comuna-card padding relative">

                <button onClick={onClose} style={{position:"absolute",top:20,right:20}}>
					<img src={closeIcon}
						 style={{ width: 24, height: "auto" }}
						 alt=""
						/>
				</button>

                <h1 className="text-2xl color-black font-bold text-left" style={{width:624}}>Assign TA agent</h1>
                
                <div className="rounded-3xl border-2 border-gray-300 flex flex-row p-2 mt-6" style={{width:624}}>
					<img src={searchIcon} style={{width:27}} alt="magnifying glass icon" />
					<input 
						onChange={event => setQuery(event.target.value)}
						onKeyDown={SearchQuery}
						className="w-full" 
						type="text" 
						placeholder="Search for TA agents." />
				</div>

                <div className="flex flex-row justify-between w-full mt-4">
                    <span>Agent</span>
                    <span>Requests</span>
                </div>

                <div style={{height:424, overflowY:"auto", width:"100%"}}>
                    
                    {filtered && filtered.map((item:any, index:number)=>{
                        return (
                            <div onClick={()=>{ setSelectedAgent(item); console.log("selected date: ", item); }} 
                                className={"cursor-pointer bg-gray-1 rounded-xl my-2 p-4 flex flex-row justify-between "+(selectedAgent===item?"border-2 border-comuna-purple":"border-2 border-gray-1")}
                            >
                                <span className="font-medium">{item.first_name+" "+item.last_name}</span>
                                <span className="font-bold text-xl">{item.requests}</span>
                            </div>
                        )
                    })}
                </div>

                <div className="flex flex-row mt-4">
                    <button className="comuna-secondary-button mr-2" onClick={onClose}>Cancel</button>
                    <button className={"ml-2 "+(selectedAgent ? "comuna-primary-button":"comuna-disabled-button")} onClick={()=>{ setConfirm(true); }}>Assign this agent</button>
                </div>

            </div>}
        </div>
    )
}

export default SearchForComunaAgent;