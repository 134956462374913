import { useEffect, useState } from "react";
import { ComunaAPI } from "../../managers/ComunaAPI";
import { LocalStorageManager } from "../../managers/LocalStorageManager";
import { PurpleCasedItem } from "../PurpleCasedItem";

interface OfferComponentProps {
	application: any;
	resourceRequest: any;
}

const OfferComponent: React.FC<OfferComponentProps> = ({
	application,
	resourceRequest,
}) => {
	const token = LocalStorageManager.getToken();

	const [benefitDefs, setBenefitDefs] = useState<any[]>([]);

	useEffect(() => {
		getBenefitDefinitions();
	}, []);

	async function getBenefitDefinitions() {
		try {
			const response = await ComunaAPI.getResReqFields(token);
			console.log("proposal fields: ", response);

			setBenefitDefs(response.benefits);
		} catch (error) {
			console.log(error);
		} finally {
		}
	}

	return (
		<div className="w-full h-fit flex flex-col bg-white rounded-xl p-4 justify-start">
			<label className="w-full h-fit text-md font-semibold text-gray-700">
				Contract
			</label>
			<div className="w-full h-fit flex flex-row mb-5">
				<div className="w-full h-full flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 mr-2">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						Length
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						1 year
					</label>
				</div>
				<div className="w-full h-fit flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 ml-3">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						Hours
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						160 / month
					</label>
				</div>
			</div>
			<div className="w-full h-fit flex flex-row mb-5">
				<div className="w-full h-full flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 mr-2">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						Start date
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						{application.proposal == null
							? 
							"Undefined":
							application.proposal.start_date
						}
					</label>
				</div>
				<div className="w-full h-fit flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 ml-3">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						End date
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						Undef
					</label>
				</div>
			</div>
			<div className="w-full h-fit flex flex-row mb-5">
				<div className="w-full h-full flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 mr-2">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						Payment cadence
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						{application.proposal.payment_cadence === null
							? "N/A"
							: application.proposal.payment_cadence}
					</label>
				</div>
				<div className="w-full h-fit flex flex-row bg-gray-200 rounded-xl justify-stretch items-center p-5 ml-3">
					<label className="w-full h-fit text-sm text-gray-700 text-start">
						Salary
					</label>
					<label className="w-full h-fit text-sm font-semibold text-gray-700 text-end">
						{application.proposal.yearly_salary === null
							? "N/A"
							: application.proposal.yearly_salary}
					</label>
				</div>
			</div>
			<div className="MIDDLE w-full h-fit flex flex-col justify-stretch items-center mb-5">
				<label className="w-full h-fit text- font-semibold text-gray-700 text-start">
					Benefits
				</label>
				<div className="flex flex-row w-full h-fit justify-start items-center">
					<div className="flex flex-row h-fit w-full">
						{resourceRequest.benefits.map((benef: number, i: number) => {
							return (
								<>
									{benefitDefs.map((benefDef: any, j: number) => {
										return (
											<>
												{j === benef && (
													<PurpleCasedItem
														key={j}
														benefit={benefDef}
														onDelete={() => {}}
														canDelete={false}
													/>
												)}
											</>
										);
									})}
								</>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OfferComponent;
