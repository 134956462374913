import axios from "axios";
import { BASE_URL } from "../config";

const getCookie = (name) => {
	let cookieValue = null;
	if (document.cookie && document.cookie !== "") {
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === name + "=") {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				console.log("CSFR TOKEN VALUE: ", cookieValue);
				break;
			}
		}
		return cookieValue;
	}
};

const defaultHeaders = {
	"Content-Type": "application/json",
	"X-CSRFToken": getCookie("csrftoken"),
};

const authenticatedheaders = (token, isFormData = false) => {
	if (isFormData) {
		return {
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	}
	return {
		"Content-Type": "application/json",
		Authorization: `Token ${token}`,
		"X-CSRFToken": getCookie("csrftoken"),
	};
};

const defaultConfig = {
	headers: defaultHeaders,
};

export class ComunaAPI {
	static async signIn(email, password) {
		const signInInfo = {
			username: email.toLowerCase(),
			password: password,
		};
		const completeUrl = `${BASE_URL}signin`;
		const response = await axios.post(completeUrl, signInInfo, defaultConfig);
		console.log(response.data);
		return response.data;
	}

	static async signUp(email, password, password2) {
		//make sure email field is minus caps
		const signUpInfo = {
			email: email.toLowerCase(),
			password: password,
			password2: password2,
		};
		const completeUrl = `${BASE_URL}signup/client`;
		const response = await axios.post(completeUrl, signUpInfo, defaultConfig);
		return response.data;
	}

	static async signUpContractor(contractorInfo) {
		const completeUrl = `${BASE_URL}signup/contractor`;
		console.log("url: " + completeUrl + " contractorInfo: ");
		console.log(contractorInfo);

		const response = await axios.post(
			completeUrl,
			contractorInfo,
			defaultConfig
		);
		console.log(response.data);
		return response.data;
	}

	static async getUserData(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};
		const completeUrl = `${BASE_URL}user`;
		const response = await axios.get(completeUrl, { headers: headers });
		// console.log(response.data);
		return response.data;
	}

	static async step1VerifyEmail(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};
		const completeUrl = `${BASE_URL}verify/email`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async step2VerifyEmail(token, emailCode) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};
		const data = {
			code: emailCode,
		};
		const completeUrl = `${BASE_URL}verify/email`;
		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async SendEditResourceRequest(token, roleInfo, benefits, rrId) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};

		//turn skills into an array of numbers
		var skills_ = [];
		roleInfo.roleSkills.forEach((skill) => {
			skills_.push(skill.value);
		});

		const newResReqInfo = {
			role_name: roleInfo.roleName,
			quantity: roleInfo.roleQuantity,
			modality: roleInfo.roleModality,
			seniority: roleInfo.roleSeniority,
			skills: skills_,
			position_overview: roleInfo.roleDescription,
			benefits: benefits,
			start_date: roleInfo.roleStartDate,
			location: roleInfo.roleLocation,
			additional_details: roleInfo.roleDetails,
		};
		const completeUrl = `${BASE_URL}resource_request/` + rrId + "/edit";

		try {
			const response = await axios.post(completeUrl, newResReqInfo, {
				headers: headers,
			});
			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async softDeleteResourceRequest(token, rrId) {
		console.log(
			"soft deleting resource request token: " + token + " rrId: " + rrId
		);
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};
		const completeUrl = `${BASE_URL}resource_request/` + rrId + "/delete";
		const data = {};
		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async getUserResourceRequests(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}resource_request`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async getResReqFields(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}resource_request/fields`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async getResourceRequestInfoById(token, id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}resource_request/${id}`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log("RR by ID " + response.data);
		return response.data;
	}

	
	static async GetInterviewQuestions(token, id){
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}resource_request/${id}/interviewquestions`;
		try {
			const response = await axios.get(completeUrl, {
				headers: headers,
			});
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async GetOrganizationProfile(token) {
		const completeUrl = `${BASE_URL}organization_profile`;
		try {
			const response = await axios.get(completeUrl, {
				headers: authenticatedheaders(token),
			});
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async CreateOrganization(token, newOrgInfo) {
		const completeUrl = `${BASE_URL}organization_profile`;
		const formData = new FormData();
		formData.append("name", newOrgInfo.name);
		formData.append("description", newOrgInfo.description);
		
		if(newOrgInfo.client_email){
			formData.append("client_email", newOrgInfo.client_email);
		}

		if (newOrgInfo.profile_picture) {
			//add a timestamp to the name of the file to prevent overriding existing ones
			const timestamp = new Date().getTime();
			const fileName = newOrgInfo.name.replace(/ /g, "_") + timestamp+"."+getImageFormat(newOrgInfo.profile_picture.name);
			formData.append("profile_picture", newOrgInfo.profile_picture, fileName);
		}
		formData.append("company_size", newOrgInfo.company_size);
		formData.append("team_name", newOrgInfo.team_name);
		formData.append("referral", newOrgInfo.referral);

		const response = await axios.post(completeUrl, formData, {
			headers: authenticatedheaders(token, true),
		});
		if (response.status === 201 || response.status === 200) {
			return true;
		}
		return false;
	}

	static async getProposalFields(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal/fields`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetUserProposals(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetUserContracts(token) {
		const completeUrl = `${BASE_URL}contracts`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetContractPreview(token) {
		const completeUrl = `${BASE_URL}contract/preview`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetContractPreviewWithProposalId(token, proposalId) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}contract/preview/${proposalId}`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async SignContract(token, data) {
		const completeUrl = `${BASE_URL}contract/sign`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetInvoices(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}invoice`;
		// try {
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		// if (response.status === 200) {
		return response.data;
		// } else {
		// 	return null;
		// }
		// } catch (error) {
		// 	return null;
		// }
	}

	static async CreateInvoice(token, data) {
		const completeUrl = `${BASE_URL}invoice/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async PayInvoice(token, data) {
		const completeUrl = `${BASE_URL}invoice/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async ChallengeInvoice(token, data) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}invoice/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async CreateProposal(token, proposal) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal`;
		const response = await axios.post(completeUrl, proposal, {
			headers: headers,
		});
		console.log(response.data);
		return response.data;
	}

	static async ModifyProposal(token, id, proposal) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal/${id}/edit`;
		const response = await axios.post(completeUrl, proposal, {
			headers: headers,
		});
		console.log(response.data);
		return response.data;
	}

	static async UpdateProposal(token, id, proposalUpdate) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}proposal/${id}`;
		const response = await axios.post(completeUrl, proposalUpdate, {
			headers: headers,
		});
		console.log(response.data);
		return response.data;
	}

	static async UpdateApplication(token, id, updateInfo) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}application/${id}`;
		const response = await axios.post(completeUrl, updateInfo, {
			headers: headers,
		});
		return response.data;
	}

	static async AskForInterviewApplication(token, id, data) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}application/${id}/interview`;
		const response = await axios.post(completeUrl, data, {
			headers: headers,
		});
		return response.data;
	}

	static async GetInterview(token, id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	
		const completeUrl = `${BASE_URL}interview/${id}`;
		try {
			const response = await axios.get(completeUrl, { headers: headers });
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			console.error("Error fetching contractor interviews", error);
			return null;
		}
	}

	static async MakeInterviewViewed(token, id){
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}interview/${id}/mark_viewed`;
		const response = await axios.post(completeUrl, {}, { headers: headers });
		return response.data;
	}

	static async GetApplication(token, id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}application/${id}`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetPaymentMethods(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}withdraw_methods`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async GetMyTeam(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}myteam/`;
		const response = await axios.get(completeUrl, { headers: headers });
		return response.data;
	}

	static async SendHelpMessage(token, helpInfo) {
		const completeUrl = `${BASE_URL}help_ticket/`;
		const response = await axios.post(completeUrl, helpInfo, {
			headers: authenticatedheaders(token),
		});
		if (response.status === 201 || response.status === 200) {
			return true;
		}
		return false;
	}

	static async UpdateContractorContract(token, id, data) {
		const completeUrl = `${BASE_URL}contract/edit/` + id;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async getUpdatesForContractorContracts(token) {
		const completeUrl = `${BASE_URL}contract/edit/`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async setNewFinishDateForContractorContract(token, data) {
		const completeUrl = `${BASE_URL}contract/end/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async createOneTimePaymentForcontractorContract(token, id, data) {
		const completeUrl = `${BASE_URL}one_time_payment/` + id;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async cancelOneTimePaymentForcontractorContract(token, id) {
		const completeUrl = `${BASE_URL}one_time_payment/delete/` + id;
		const response = await axios.delete(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async UpdateAccountDetails(token, data) {
		const completeUrl = `${BASE_URL}account/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetWithdrawalMethods(token) {
		const completeUrl = `${BASE_URL}withdraw_methods/`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async AddWithdrawalMethods(token, data) {
		const completeUrl = `${BASE_URL}withdraw_methods/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async getInterviewDates(uuid) {
		const headers = {
			"Content-Type": "application/json",
		};

		const completeUrl = `${BASE_URL}application/interview/answer?uuid=${uuid}`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async answerInterview(uuid, action, acceptedDate) {
		const headers = {
			"Content-Type": "application/json",
		};

		const completeUrl = `${BASE_URL}application/interview/answer`;

		const data = {
			uuid: uuid,
			update: action,
			accepted_date: acceptedDate,
		};

		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async triggerForgotPassword(email) {
		const headers = {
			"Content-Type": "application/json",
		};

		const completeUrl = `${BASE_URL}user/forgot_password`;

		const data = {
			email: email,
		};
		console.log("complete url is: ", completeUrl);
		console.log("payload is: ", data);

		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async forgotPasswordConfirm(userId, emailToken, newPassword) {
		const headers = {
			"Content-Type": "application/json",
		};
		console.log("complete url is:");
		const completeUrl = `${BASE_URL}user/reset_password/${userId}/${emailToken}`;
		console.log(completeUrl);
		const data = {
			password: newPassword,
		};

		const response = await axios.post(completeUrl, data, { headers: headers });
		console.log(response.data);
		return response.data;
	}

	static async startEmailUpdate(token, data) {
		const completeUrl = `${BASE_URL}email-update/`;
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async emailUpdate(token, data, userid, uuid) {
		console.log("data is: ", data);
		console.log("userid is: ", userid);
		console.log("uuid is: ", uuid);
		const completeUrl = `${BASE_URL}email-update/confirm/${userid}/${uuid}/`;
		console.log("complete url is: ", completeUrl);
		const response = await axios.post(completeUrl, data, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async previewInvoiceContractor(token) {
		const completeUrl = `${BASE_URL}invoice/preview`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	///STAFF

	static async getResourceRequestForStaff(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}staff/resource_request`;
		const response = await axios.get(completeUrl, { headers: headers });
		console.log("RR by ID " + response.data);
		return response.data;
	}

	static async getStaffResourceRequestById(token, id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}staff/resource_request/` + id;
		const response = await axios.get(completeUrl, { headers: headers });
		// console.log("detail RR by ID " + response.data);
		return response.data;
	}

	static async getStaffNotifications(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}notifications`;
		const response = await axios.get(completeUrl, { headers: headers });
		// console.log("detail RR by ID " + response.data);
		return response.data;
	}

	static async clearStaffNotifications(token, notificationId) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		const completeUrl = `${BASE_URL}notifications/clear/` + notificationId;
		// console.log(
		// 	"clearing notification: " + notificationId + " complete url is: ",
		// 	completeUrl
		// );
		const data = { stuff: "stuff" };
		const response = await axios.post(completeUrl, data, { headers: headers });
		// console.log("detail RR by ID " + response.data);
		return response.data;
	}

	static async StaffUpdateApplication(token, id, updateInfo) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
		const completeUrl = `${BASE_URL}staff/application/message/${id}`;
		const response = await axios.post(completeUrl, updateInfo, {
			headers: headers,
		});
		return response.data;
	}
	
	static async GetQuestionCategories(token) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	
		const completeUrl = `${BASE_URL}question_categories`;
		try {
			const response = await axios.get(completeUrl, { headers: headers });
			if (response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			console.error("Error fetching question categories", error);
			return null;
		}
	}
}
function getImageFormat(filename) {
	// Use regular expression to extract the image format
	const match = filename.match(/\.(.+)$/);

	// Check if a match is found
	if (match && match[1]) {
		// Convert the image format to lowercase for consistency
		return match[1].toLowerCase();
	} else {
		// Return null if no match is found
		return null;
	}
}