import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { ComunaStaffAPI } from '../../managers/ComunaStaffAPI';
import { LocalStorageManager } from '../../managers/LocalStorageManager';
import { CeleryTaskManager } from '../../managers/CeleryTaskManager';

interface ClientCallPopupProps {
    isVisible: boolean;
    onClose: () => void;
    onResourcesReceived: (resources: any[], client_call_id: number) => void;
    organizations: any[];
}

const ClientCallPopup: React.FC<ClientCallPopupProps> = ({ isVisible, onClose, onResourcesReceived, organizations }) => {
    const closeIcon = require("../../img/icons/x.png");

    const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
    const [videoLink, setVideoLink] = useState<string>('');
    const [transcript, setTranscript] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isValidUrl = (url: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        let validationErrors = { ...errors };

        if (name === 'videoLink') {
            setVideoLink(value);
            if (!isValidUrl(value)) {
                validationErrors[name] = 'Please enter a valid URL including the protocol (http:// or https://)';
            } else {
                delete validationErrors[name];
            }
        } else if (name === 'file' && event.target instanceof HTMLInputElement) {
            setFile(event.target.files ? event.target.files[0] : null);
        } else {
            const setter: any = {
                selectedOrganization: setSelectedOrganization,
                videoLink: setVideoLink,
                transcript: setTranscript
            };
            setter[name](value);
        }

        setErrors(validationErrors);
    };

    const handleSubmit = async () => {
        let validationErrors = { ...errors };
        if (!isValidUrl(videoLink)) {
            validationErrors['videoLink'] = 'Please enter a valid URL including the protocol (http:// or https://)';
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('organization_profile', selectedOrganization.value);
        formData.append('video_link', videoLink);
        if (file) {
            formData.append('transcription_file', file);
        }
        formData.append('notes', transcript);

        const token = await LocalStorageManager.getToken();
        const clientCall = await ComunaStaffAPI.CreateClientCall(token, formData);

        if (!clientCall || !clientCall.id) {
            setIsLoading(false);
            return;
        }

        console.log("Client Call Created:", clientCall);
        const taskResponse = await ComunaStaffAPI.GenerateResourceRequestWithClientCall(token, clientCall.id, false);

        if (!taskResponse || !taskResponse.task_id) {
            setIsLoading(false);
            return;
        }
        
        console.log("Task ID:", taskResponse.task_id);
        CeleryTaskManager.pollTaskStatus(taskResponse.task_id, token, (result: any) => {
            console.log("Resource Request Generated:", result);
            onResourcesReceived(result, clientCall.id);
            setIsLoading(false);
            onClose();
        }, (error: any) => {
            console.error("Error generating resource requests:", error);
            setIsLoading(false);
        });

    };

    const isSubmitDisabled = !selectedOrganization || !videoLink || (!transcript && !file) || isLoading;

    const portalRoot = document.getElementById('root');
    if (!isVisible || !portalRoot) return null;

    return ReactDOM.createPortal(
        <div className="comuna-popup open" id="client-call-popup">
            <div
                className="bg-white rounded-xl p-12 relative fade-in"
                style={{ maxWidth: 1014, width: "90%" }}>
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
                    <img src={closeIcon} alt="icon of an x" />
                </button>

                <Select
                    name="selectedOrganization"
                    value={selectedOrganization}
                    onChange={(selectedOption) => {
                        setSelectedOrganization(selectedOption);
                    }}
                    options={organizations}
                    placeholder="Select Organization"
                />

                <input
                    type="text"
                    name="videoLink"
                    placeholder="Enter video link (e.g., https://example.com)"
                    value={videoLink}
                    onChange={handleChange}
                    className="block w-full p-2 mt-4 border border-gray-300 rounded"
                />
                {errors.videoLink && <p className="text-red-500">{errors.videoLink}</p>}
                <textarea
                    name="transcript"
                    placeholder="Enter notes here or upload a file"
                    value={transcript}
                    onChange={handleChange}
                    className="block w-full p-2 mt-4 border border-gray-300 rounded h-24"
                />
                <input
                    type="file"
                    name="file"
                    onChange={handleChange}
                    className="mt-3 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                />
                <button
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                    className={`mt-4 px-4 py-2 rounded bg-blue-500 text-white ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
                >
                    {isLoading ? 'Loading...' : 'Generate resource requests'}
                </button>
            </div>
        </div>,
        portalRoot
    );
};

export default ClientCallPopup;