export function Footer() {
    return (
        <footer className="bg-gray-800 text-white py-4">
            <div className="container mx-auto px-4">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="text-sm mb-2 sm:mb-0">
                        &copy; {new Date().getFullYear()} Comuna. All rights reserved.
                    </div>
                    <div>
                        <a href="https://upstart13.atlassian.net/wiki/external/NWVkYzNhM2IzNTgxNDA5MDlhNTRjZTk0ODhjMmQ1OWI" target="_blank" className="text-sm text-gray-400 hover:text-white">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
