import React, { useEffect, useState } from 'react';
import InterviewHeaderComponent from './InterviewHeaderComponent';

const happy_face = require("../../img/happy-face.png");
const medium_face = require("../../img/medium-face.png");
const sad_face = require("../../img/sad-face.png");
const link_arrow = require("../../img/arrow-link.png");

interface CompatibilityComponentProps {
    agent: any;
    contractor: any;
    averageScore: number;
    comuna_agent?: string;
    interview?: any;
    small?: boolean;
    company_name?: string;
}

const getCompatibilityMessage = (score: number) => {
    if (score >= 7) {
        return <p className='text-black' style={{ lineHeight: 1 }}>Your compatibility with this candidate is <b>Excellent.</b> They demonstrate a high level of proficiency across essential skills for the role.</p>;
    } else if (score > 5) {
        return <p className='text-black' style={{ lineHeight: 1 }}>Your compatibility with this candidate is <b>Regular.</b> They meet some of the job requirements but may need further development in certain areas.</p>
    } else {
        return <p className='text-black' style={{ lineHeight: 1 }}>Your compatibility with this candidate is <b>Low.</b> They currently fall short in several key areas required for the position, which might necessitate significant training or reconsideration of their fit for this role."</p>
    }
};

const getCompatibilityImage = (score: number) => {
    if (score >= 7) {
        return happy_face;
    } else if (score > 5) {
        return medium_face;
    } else {
        return sad_face;
    }
}

const GetCompatibilityColors = (score: number) => {
    if (score >= 7) {
        return { bgColor: "#B0E8CF", barColor: "#47B888" };
    } else if (score > 5) {
        return { bgColor: "#FFE0A9", barColor: "#CC8200" };
    } else {
        return { bgColor: "#FAB8BF", barColor: "#F02940" };
    }
}


const CompatibilityComponent: React.FC<CompatibilityComponentProps> = ({ agent, contractor, averageScore, small = false, interview, company_name }) => {
    const { bgColor, barColor } = GetCompatibilityColors(averageScore);


    const GetCompatibilityOverview = (score: number) => {
        const templates: any = {
            1: `A terrible match; this candidate is a really bad fit for ${company_name}.`,
            2: `This candidate is really not a fit for ${company_name}; were they even trying?`,
            3: `Not quite right; this candidate is a poor fit for  ${company_name}.`,
            4: `Could be better; this candidate is a below average fit for  ${company_name}.`,
            5: `Just okay; this candidate is an average fit for  ${company_name}.`,
            6: `A bit promising; this candidate is slightly above average for  ${company_name}.`,
            7: `Pretty good; this candidate is a solid fit for  ${company_name}.`,
            8: `Really solid; this candidate is a very good fit for  ${company_name}.`,
            9: `Outstanding; this candidate is an excellent fit for  ${company_name}.`,
            10: `Absolutely ideal; this candidate is the perfect fit for  ${company_name}.`
        };

        // Rounding the score to the nearest integer
        let roundedScore = Math.round(score);

        // Ensuring the rounded score is within the valid range
        if (roundedScore < 1) roundedScore = 1;
        if (roundedScore > 10) roundedScore = 10;

        // Returning the appropriate template
        return templates[roundedScore];
    }

    const [videoExists, setVideoExists] = useState<boolean>(true);

    const isS3Link = (link: string) => {
        return link.includes('s3.amazonaws.com');
    };

    const handleVideoError = () => {
        setVideoExists(false);
    };

    if (small) {
        return (
            <div className='flex flex-row p-4 rounded-xl items-center' style={{ background: bgColor }}>
                <img src={getCompatibilityImage(averageScore)} alt="" style={{ width: 60, height: 60 }} />
                <h2 className='ml-4 text-5xl'>{averageScore.toFixed(1)}/10</h2>

                <div className='w-full ml-2'>
                    <p style={{ lineHeight: 1, maxWidth: "16em" }} >{GetCompatibilityOverview(averageScore)}</p>
                </div>

                <img src={link_arrow} alt="" style={{ width: 60, height: 60 }} />
            </div>
        );
    } else {
        return (
            <div className='flex flex-row p-8 rounded-xl items-center mx-2 mb-4' style={{ background: bgColor }}>

                <div className='flex flex-col' style={{ width: "40%" }}>
                    <div className='flex flex-row items-center mb-4'>
                        <img src={getCompatibilityImage(averageScore)} alt="" style={{ width: 100, height: 100 }} />
                        <h2 className='ml-4 text-5xl'>{averageScore.toFixed(1)}/10</h2>
                    </div>
                    <div style={{ background: "#ccc", width: "100%", height: 8, borderRadius: 14, marginBottom: 10 }}>
                        <div style={{ background: barColor, width: Math.round(averageScore) * 10 + "%", height: "100%", borderRadius: 14, }}></div>
                    </div>
                    {getCompatibilityMessage(averageScore)}

                    {interview &&
                        <div className='bg-white rounded-xl p-2 mt-4'>
                            <InterviewHeaderComponent
                                date={interview.date}
                                duration={interview.duration}
                                agent={agent}
                                contractor={contractor}
                                simplifiedDisplay={true}
                            />
                        </div>
                    }


                </div>

                <div className='flex flex-col ml-4' style={{ width: "60%" }}>
                    <div className="w-full h-[270px]">
                        {interview && videoExists ? (
                            isS3Link(interview.video_link) ? (
                                <video
                                    className="w-full h-full rounded-xl"
                                    controls controlsList="nodownload"
                                    onError={handleVideoError}>
                                    <source src={interview.video_link} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <iframe
                                    title="Interview Video"
                                    className="w-full h-full rounded-xl"
                                    src={interview.video_link}
                                    onError={handleVideoError}
                                    allowFullScreen
                                ></iframe>
                            )
                        ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-200 text-red-500">
                                The video is not available.
                            </div>
                        )}
                    </div>
                </div>


            </div>
        );
    }

};

export default CompatibilityComponent;