import { DateTime } from "luxon";

export function ValidateRoles(roles:any[], setRoles:any, checkOrg:boolean = false):boolean{
    var passes = true; //general flag

    const newRoles = [...roles];

    newRoles.map((role) => {
        role.nameCheck = role.roleName !== "";
        role.organizationCheck = !checkOrg || role.roleOrganization != null;
        role.descriptionCheck =
            role.roleDescription !== "" && role.roleDescription.length < 2049;
        role.skillsCheck = role.roleSkills.length > 0;
        // debugger;
        
        role.dateGood = true;

        role.good =
            role.nameCheck &&
            role.descriptionCheck &&
            role.skillsCheck &&
            role.dateGood &&
            role.organizationCheck;
        //check if role startdate is in the future

        //if one role check does not pass we cant move forward
        if (!role.good) {
            passes = false;
        }
    });
    console.log("newRoles: ", newRoles);
    setRoles(newRoles);

    return passes;
}