import React from 'react';
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';

interface RadarChartProps {
    theoryScore: number;
    applicationScore: number;
    communicationScore: number;
    problemSolvingScore: number;
    cultureFitScore: number;
}

const RadarChartComponent: React.FC<RadarChartProps> = ({
    theoryScore,
    applicationScore,
    communicationScore,
    problemSolvingScore,
    cultureFitScore
}) => {
    // Function to determine color and background based on score
    const getStyles = (score: number) => {
        if (score >= 70) return { color: '#07754D', backgroundColor: '#EFFAF5' };
        else if (score >= 51 && score < 70) return { color: '#5C5600', backgroundColor: '#FFFBC2' };
        else return { color: '#5F0711', backgroundColor: '#FCCFD5' };
    };

    const labels = ['Theory', 'Application', 'Communication', 'Problem Solving', 'Culture Fit'];
    const scores = [theoryScore, applicationScore, communicationScore, problemSolvingScore, cultureFitScore];

    const filteredLabels = labels.filter((label, index) => scores[index] > 0);
    const filteredScores = scores.filter(score => score > 0);

    const data = {
        labels: filteredLabels,
        datasets: [{
            label: 'Candidate Score',
            data: filteredScores.map(score => Math.round(score * 10)),
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            pointBackgroundColor: filteredScores.map(score => getStyles(Math.round(score * 10)).backgroundColor),
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointRadius: 16,
            pointHoverRadius: 20,
        }]
    };

    const options = {
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    display: false,
                    stepSize: 20,
                    backdropColor: 'rgba(255, 255, 255, 0)',
                },
                pointLabels: {
                    font: {
                        size: 14
                    },
                    color: '#666'
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context: any) {
                        return context.dataset.label + ': ' + context.raw; // Custom tooltip format
                    }
                }
            }
        },
        elements: {
            line: {
                borderWidth: 3
            },
            point: {
                radius: 16 // Apply a larger radius for all points
            }
        },
    };

    const plugins = [{
        id: 'textInsidePoints',
        afterDatasetsDraw: (chart: any) => {
            const ctx = chart.ctx;
            chart.getDatasetMeta(0).data.forEach((point: any, index: any) => {
                const style = getStyles(scores[index] * 10);
                ctx.fillStyle = style.color; // Text color
                ctx.font = 'bold 12px Arial'; // Bold text style and size
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                // Rounding the values to the nearest whole number
                ctx.fillText(Math.round(scores[index] * 10), point.x, point.y);
            });
        }
    }];

    return <Radar data={data} options={options} plugins={plugins} />;
}

export default RadarChartComponent;
