import React, { useEffect, useState } from "react";
import "./Comuna.scss";
import logo from "./logo.svg";
import "./App.css";

import { LoginView } from "./routes/login";

import Todo from "./components/todo";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LocalStorageManager } from "./managers/LocalStorageManager";
import { UserContext } from "./Providers/UserContext";
import { ComunaAPI } from "./managers/ComunaAPI";
import { StaffRoot } from "./routes/StaffRoot";
import { loader as StaffLoader } from "./routes/StaffRoot";
import ApplicationForm from "./components/ApplicationForm";

import {
	RequestsView,
	loader as requestsViewLoader,
} from "./routes/RequestsView";

import {
	RequestDetailViewV1,
	loader as resourceRequestDetailLoaderV1,
} from "./routes/RequestDetailViewV1.1";


import { MessagesView } from "./routes/MessagesView";
import { loader as MessagesLoader } from "./routes/MessagesView";
import { RequestDetailCard } from "./routes/RequestDetailCard";
import { RequestQuestionsCard } from "./routes/RequestQuestionsCard";
import ApplicationDetailView from "./routes/ApplicationDetailView";
import { loader as applicationDetailViewLoader } from "./routes/ApplicationDetailView";
import { LabelsCard } from "./routes/LabelsCard";
import { DeleteLabelPopup } from "./routes/DeleteLabelPopup"; 
import { CandidateSearchCard } from "./components/CandidateSearchCard";
import { EditRequestForm, Loader as EditRequestFormLoader} from "./routes/RequestEditForm";

//auth router for unknown users.
const mainRouter = createBrowserRouter([
	{
		path: "/",
		element: <LoginView />,
	},
]);

const staffRouter = createBrowserRouter([
	{
		path: "/",
		element: <StaffRoot />,
		id: "staff_root",
		loader: StaffLoader,
		children: [
			{
				path: "requests",
				element: <RequestsView />,
				loader: requestsViewLoader,
				children: [
					{
						path: "labels", 
						element: <LabelsCard />,
						children: [
							{
								path: "delete/:label_id",
								element: <DeleteLabelPopup />,
							}
						],
					}
				],
			},
			{
				id: "resource_request_detail",
				path: "requests/:request_id",
				element: <RequestDetailViewV1 />,
				loader: resourceRequestDetailLoaderV1,
				children: [
					{
						id: "application_detail",
						path: "application/:application_id",
						element: <ApplicationDetailView />,
						loader: applicationDetailViewLoader,
						children: [
							{
								path: "edit",
								element: <ApplicationForm />,
								// loader: ApplicationFormLoader
							},
						],
					},
					{
						path: "detail",
						element: <RequestDetailCard />,
						children: [
							{
								path: "edit",
								element: <EditRequestForm/>,
								loader: EditRequestFormLoader,
							},
						],
					},
					{
						path: "questions",
						element: <RequestQuestionsCard />,
					},
					{ path: "new", element: <ApplicationForm /> },
					{ path: "edit/:application_id", element: <ApplicationForm /> },
					{ path: "search", element: <CandidateSearchCard /> }
				],
			},
			{
				path: "messages",
				element: <MessagesView />,
				id: "messages",
				loader: MessagesLoader,
				// children: [
				// 	{ path: "Chat", element: <ApplicationForm /> },
				// 	{ path: "edit/:application_id", element: <ApplicationForm /> },
				// ],
			},
			{ path: "clients", element: <Todo /> },
			{ path: "contractors", element: <Todo /> },
		],
	},
]);

interface User {
	email_verified: boolean;
	first_name: string;
	last_name: string;
	phone_number: string;
	phone_verified: boolean;
	profile_type: string;
	username: string;
	organization: boolean;
}

function App() {
	const [token, setToken] = useState(LocalStorageManager.getToken());
	const [user, setUser] = useState<User | null>();
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setLoading(true);
		if (token) {
			LocalStorageManager.setToken(token);
			LoadData();
		} else {
			setUser(null);
			setLoading(false);
		}
		console.log("user", user);
	}, [token]);

	const LoadData = async () => {
		try {
			await getUserData();
			setLoading(false);
		} catch (error) {
			setToken("");
		}
	};

	async function getUserData() {
		const userData = await ComunaAPI.getUserData(token);
		setUser(userData);
	}

	async function logOut() {
		setToken("");
		setUser(null);
		LocalStorageManager.setToken("");
	}

	//by default we are using the auth router for non authenticated users.
	let currentRouter = mainRouter;

	//if we have a valid token and it is a client

	if (token !== "" && user?.profile_type === "client") {
		console.log("client, go fish");
	}

	//if we have a valid token and it is a contractor
	else if (token !== "" && user?.profile_type === "contractor") {
		console.log("contractor, go fish");
	}

	//if we have a valid token and it is a staff
	if (token !== "" && user?.profile_type === "staff") {
		console.log("staff, come on in");
		currentRouter = staffRouter;
	}

	if (loading) {
		return <div></div>;
	}

	return (
		<UserContext.Provider
			value={{
				user,
				token,
				setToken,
				logOut,
			}}>
			<RouterProvider router={currentRouter} />
		</UserContext.Provider>
	);
}

export default App;
