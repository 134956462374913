import React, { useEffect, useState } from "react";
import { stateColor } from "../../config";

interface ContractorItemProps {
	application: any;
	selected: boolean;
	onSelectApplication: (application: any) => void;
	notifications: any[];
}

const ContractorItem: React.FC<ContractorItemProps> = ({
	application,
	onSelectApplication,
	selected,
	notifications,
}) => {
	const defaultProfilePicture = require("../../img/icons/emptyAvatar.png");

	const [applicationState, setApplicationState] = useState("Unknown");

	const [applicationColor, setApplicationColor] = useState("");

	const [notifCount, setNotifCount] = useState(0);

	const [markerColor, setMarkerColor] = useState("");

	useEffect(() => {
		console.log("pic url: " + application.contractor.profile_picture);
		// setClickable(application.state > 2);
		if (application.proposal !== null && application.proposal !== undefined) {
			showDecisionMarker(application.proposal.state, application.state);
		} else if (application.state === 6) {
			showDecisionMarker(3, 6);
		}
	}, [application]);

	function showDecisionMarker(proposalState: number, applicationState: number) {
		if (proposalState === 0) {
			// offer sent
			setMarkerColor(" bg-blue-500");
		} else if (proposalState === 1) {
			// accepted
			setMarkerColor(" bg-green-500");
		} else if (proposalState === 2 || applicationState === 6) {
			//soft rejected
			setMarkerColor(" bg-red-500");
		} else if (proposalState === 3 || applicationState === 6) {
			//rejected
			setMarkerColor(" bg-red-500");
		} else {
			setMarkerColor(" bg-slate-300");
		}
	}

	useEffect(() => {
		var count = 0;
		// console.log("notifications: ", notifications);

		notifications.forEach((notification) => {
			if (
				notification.target === application.id &&
				notification.cleared === false
			) {
				count += notification.counter;
			}
		});

		setNotifCount(count);
	}, [notifications, application]);

	return (
		<div
			onClick={() => {
				onSelectApplication(application);
			}}
			className={
				"flex flex-row w-full h-fit rounded-xl py-3 pl-6 pr-4 mb-2 cursor-pointer " +
				(selected
					? " bg-comuna-purple-transparent border-4 border-comuna-purple"
					: " bg-gray-100")
			}>
			{application.contractor.picture ? (
				<img
					alt="contractor"
					style={{ width: 108, height: 54 }}
					className="rounded-full"
					src={application.contractor.picture}
					onError={({ currentTarget }) => {
						console.log("error", currentTarget);
						currentTarget.onerror = null;
						currentTarget.src = defaultProfilePicture;
					}}
				/>
			) : (
				<div
					style={{ width: 108, height: 54 }}
					className="bg-gray-400 rounded-full font-bold text-white flex justify-center items-center">
					{application.contractor.first_name[0] +
						application.contractor.last_name[0]}
				</div>
			)}
			<div className="flex flex-col w-full h-full ml-4 justify-center">
				<b className="w-full h-fit text-lg font-semibold text-black">
					{application.contractor.first_name +
						" " +
						application.contractor.last_name}
				</b>
				<p className="w-full h-fit text-sm font-medium text-gray-700">
					{application.contractor.country_location}
				</p>

				<label
					className={
						"text-white text-xs flex-wrap rounded-lg h-fit w-fit p-1 ml-2 " +
						markerColor
					}>
					{application.proposal !== null &&
						application.proposal.state === 0 &&
						"Proposal sent"}
					{application.proposal !== null &&
						application.proposal.state === 1 &&
						"Hired"}
					{application.proposal !== null &&
						(application.proposal.state === 2 ||
							application.proposal.state === 3) &&
						"Rejected"}
					{application.proposal === null &&
						application.state === 6 &&
						"Rejected"}
				</label>
			</div>
			<div className="flex flex-col w-7/12 h-full ml-4 justify-center">
				{notifCount > 0 && (
					<b className="w-6 h-6 text-white bg-comuna-purple rounded-full text-center px-1 pb-1">
						{notifCount}
					</b>
				)}
			</div>
		</div>
	);
};

export default ContractorItem;
