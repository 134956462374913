import { ComunaStaffAPI } from "./ComunaStaffAPI";

export class CeleryTaskManager {
    static pollTaskStatus(taskId, token, onSuccess, onFailure) {
        const interval = setInterval(async () => {
            try {
                const response = await ComunaStaffAPI.CheckTaskStatus(token, taskId);
                console.log(response);
                if (response.status !== 'pending') {
                    clearInterval(interval);
                    if (response.status === 'SUCCESS') {
                        onSuccess(response.result);
                    } else {
                        onFailure(response.result);
                    }
                }
            } catch (error) {
                console.error('Error polling task status:', error);
                clearInterval(interval);
                onFailure(error);
            }
        }, 5000); // Poll every 5 seconds
    }
}
