interface MessageViewInnerTopBarProps {
	window: string;
	application: any;
	onSelect: (selectedWindow: string) => void;
}

const MessageViewInnerTopBar: React.FC<MessageViewInnerTopBarProps> = ({
	window,
	application,
	onSelect,
}) => {
	function MenuOption({ label, selected, wName }: any) {
		return (
			<div
				onClick={() => {
					console.log("clicked: ", wName);
					onSelect(wName);
				}}
				className={
					(selected === true
						? "border-b-4 border-comuna-purple comuna-purple  "
						: "") +
					" w-full h-fit text-center items-center cursor-pointer px-6 py-4"
				}>
				{label}
			</div>
		);
	}

	return (
		<div className="flex flex-row justify-start items-center w-full h-fit px-4 border-b bg-white border-gray-200">
			<MenuOption label="Chat" selected={window === "chat"} wName="chat" />
			<MenuOption
				label="Interviews"
				selected={window === "interviews"}
				wName="interviews"
			/>
			<MenuOption
				label="Application details"
				selected={window === "details"}
				wName="details"
			/>
			{application.proposal && <MenuOption label="Offer" selected={window === "offer"} wName="offer" />}
		</div>
	);
};

export default MessageViewInnerTopBar;
