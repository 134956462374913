import { useState, useEffect } from "react";

export class LocalStorageManager {
	static getToken() {
		return localStorage.getItem("token");
	}

	static setToken(newToken) {
		localStorage.setItem("token", newToken);
	}

	static getInitToken() {
		return localStorage.getItem("initToken");
	}

	static setInitToken(newToken) {
		localStorage.setItem("initToken", newToken);
	}

	static getRoleInfo() {
		return localStorage.getItem("roleInfo");
	}

	static setRoleInfo(newRoleInfo) {
		localStorage.setItem("roleInfo", newRoleInfo);
	}

	static getBenefitsInfo() {
		console.log("retrieving benefits", localStorage.getItem("benefitsInfo"));
		return localStorage.getItem("benefitsInfo");
	}

	static setBenefitsInfo(newBenefitsInfo) {
		console.log("saving benefits", newBenefitsInfo);
		localStorage.setItem("benefitsInfo", newBenefitsInfo);
	}
}
