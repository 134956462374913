import { useContext, useState } from "react";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { Loader } from "./loader";

import zxcvbn from "zxcvbn";


export function SignUp({onSignedInEvent}){

    const [Loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passMatch, setPassMatch] = useState(true);
    const [error, setError] = useState("");

    const validatePassword = ()=>{
        if(password.length < 8){
            setError("Password is too short");
            return false;
        }
        if(password !== confirmPassword){
            setError("Passwords don't match");
            return false;
        }
        if(zxcvbn(password).score < 2){
            setError("Passwords is too weak, use letters numbers and symbols");
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        setError("");
        e.preventDefault();

        if(validatePassword())
        {
            signUp();
        }
    }

    async function signUp(){
        var newEmail = "";
        try{
            setLoading(true);
            const signUpReturn = await ComunaAPI.signUp(email, password, confirmPassword);
            newEmail = signUpReturn.email;

            if(newEmail === email)
            {
                signIn(email, password);
            }

        }
        catch(error){
            if(error.response.status===400 && error.response.data.email){
                setError("An account with this email, already exists. Did you forgot your password?");
            }
            else{
                setError("There was an error with your request, please try again");
            }
        }
        finally{
            setLoading(false);
        }
    }

    async function signIn(email, password){
        var newToken = "";
        try{
            const signInReturn = await ComunaAPI.signIn(email, password);
            newToken = signInReturn.token;

        }
         catch(error){
            setError(error);
        }

        if(newToken !== "")
        {
            onSignedInEvent(newToken);
        }
    }


    const regularInputClassName = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
    const errorInputClassName = "shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

    return(
        <form className="w-full pt-32"  onSubmit={handleSubmit}>
            <h2>Sign up</h2>
            <p className="mb-16">Sign Up to comuna</p>
        
            <div className="mb-4">
                <label htmlFor="email">Email</label>
                <input 
                    className="comuna-input"
                    value={email} 
                    onChange={(e)=> setEmail(e.target.value)} 
                    type="email" 
                    placeholder="Email*" id="email" name="email" />
            </div>
            <div className="mb-4">
                <label htmlFor="password">Create password</label>
                <input 
                    className="comuna-input"
                    value={password} 
                    onChange={(e)=>setPassword(e.target.value)} 
                    type="password" 
                    placeholder="Create password" 
                    id="password" 
                    name="password" />
            </div>
            <div className="mb-4">
                <label htmlFor="passwordConfirm">Repeat password</label>
                <input
                    className="comuna-input"
                    value={confirmPassword} 
                    onChange={(e)=>setConfirmPassword(e.target.value)} 
                    type="password" 
                    placeholder="Confirm password" 
                    id="passwordConfirm" 
                    name="passwordConfirm" />
            </div>
           
            <button type="submit" className="comuna-primary-button mt-8">
                {Loading ? <Loader/> : "Sign Up"} 
            </button>
            { passMatch ? null: <div className="text-red-600 mt-4">Password don't match</div>}
            { error === "" ? null: <div className="text-red-600 mt-4">{error}</div>}
           
        </form>
    )

}