import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";

interface NewMessagesProps {
    resource_request: any;
}

type Notification = {
    id: number;
    application_id: number;
    picture: string;
    name: string;
    initials: string;
    message: string;
    date: string;
    counter: number;
}

export function NewMessages({resource_request}: NewMessagesProps){

    const [notifications, setNotifications] = useState<Notification[]>([]);
    
    useEffect(()=>{
        LoadNotifications();
    },[]);

    // turns a string like 2024-04-08T15:25:22Z into something like 2 weeks ago or 2h ago
    function HowLongAgo(i: string){
        const date = new Date(i);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const seconds = diff/1000;
        const minutes = seconds/60;
        const hours = minutes/60;
        const days = hours/24;
        const weeks = days/7;
        const months = weeks/4;
        const years = months/12;

        if(seconds<60){
            return Math.floor(seconds)+" seconds ago";
        }else if(minutes<60){
            return Math.floor(minutes)+" minutes ago";
        }else if(hours<24){
            return Math.floor(hours)+" hours ago";
        }else if(days<7){
            return Math.floor(days)+" days ago";
        }else if(weeks<4){
            return Math.floor(weeks)+" weeks ago";
        }else if(months<12){
            return Math.floor(months)+" months ago";
        }else{
            return Math.floor(years)+" years ago";
        }
    }

    async function LoadNotifications(){

        var newNotifications:Notification[] = []

        // Load the notifications for the user.
        const token = await LocalStorageManager.getToken();
        let notifications = await ComunaAPI.getStaffNotifications(token);
        console.log("NOTFICIATIONS", notifications)
        //filter notifications that are cleared false
        notifications = notifications.filter((notification:any)=> notification.cleared === false);
        //get a list of this resource requests application IDS
        const applicationIds = resource_request.applications.map((application:any)=> application.id);
        //filter notifications to only show those that are related to this resource request by the target property
        const filteredNotifications = notifications.filter((notification:any)=> applicationIds.includes(notification.target));
        
        //we want to load the notifications of the user to show the messages on TOP
        for(var i = 0; i<filteredNotifications.length; i++){
            const target_application = resource_request.applications.find((application:any)=> application.id === filteredNotifications[i].target);
            const all_messages = target_application.notes.concat(target_application.notes_proposal);
            console.log("APPLICATION", target_application);
            newNotifications.push({
                id: filteredNotifications[i].id,
                application_id: filteredNotifications[i].target,
                picture: target_application.contractor.profile_picture,
                name: target_application.contractor.first_name+" "+target_application.contractor.last_name,
                initials: target_application.contractor.first_name[0]+target_application.contractor.last_name[0],
                message: all_messages[all_messages.length-1].content,
                date: HowLongAgo(filteredNotifications[i].updated_at),
                counter:  filteredNotifications[i].counter
            })
        }
    
        //and after we want to show the messages for the rest of the applications.
        //so if an application is not in the filtered notifications we want to show the last message of that application
        resource_request.applications.forEach((application:any)=>{
            if(!filteredNotifications.find((notification:any)=> notification.target === application.id)){
                const all_messages = application.notes.concat(application.notes_proposal);
                if(all_messages.length>0){
                    newNotifications.push({
                        id: 0,
                        application_id: application.id,
                        picture: application.contractor.profile_picture,
                        name: application.contractor.first_name+" "+application.contractor.last_name,
                        initials: application.contractor.first_name[0]+application.contractor.last_name[0],
                        message: all_messages[all_messages.length-1].content,
                        date: HowLongAgo(all_messages[all_messages.length-1].date_created),
                        counter: 0
                    })
                }
            }
        })
        
        setNotifications(newNotifications);
    }

    async function ClearNotification(notification:any){
        if(notification.id===0){
            return;
        }
        const token = await LocalStorageManager.getToken();
        await ComunaAPI.clearStaffNotifications(token, notification.id);
        LoadNotifications();
    }

    function MessageLink({notification}:any):any{
            // This function should return a link to the message that the notification is related to.
            return(
            <Link onClick={()=>{ClearNotification(notification)}} to={"/requests/"+resource_request.id+"/application/"+notification.application_id+"?messages=true"}>
            <div className="flex flex-row justify-center items-center py-4 px-4 cursor-pointer relative">
                
                {notification.picture && <img src={notification.picture} alt="profile" style={{width:56, height:56}} className="rounded-full mr-2"/>}
                
                {!notification.picture && 
                <div className="profile-placeholder">
                    {notification.initials}
                </div>
                }

                <div className="flex flex-col ml-2">
                    <b className="truncate w-40">{notification.name}</b>
                    <p className="truncate w-64">{notification.message}</p>
                </div>

                <div className={"bg-comuna-purple text-white font-bold w-4 h-4 p-3 rounded-full flex justify-center items-center "+(notification.counter>0?"":"opacity-0")}>
                    {notification.counter}
                </div>

                <span className={"absolute "+(notification.counter>0?"text-comuna-purple font-bold  comuna-purple":" text-gray-5 text-sm font-medium")} style={{right: 10, top:3}}>
                    {notification.date}
                </span>
            </div>
            </Link>
            )
    }

    return(
        <div>
            {notifications.map((notification, index) => {
                return(
                    <div>
                        <MessageLink notification={notification}/>
                    </div>
                )
            })}
            {notifications.length===0 && 
            <p className="text-center p-8">
                No new messages
            </p>}
        </div>
    )
}