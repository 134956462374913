//a generic componente for tags

import React from 'react';

interface TagsProps{
    tag: string;
}

const Tag: React.FC<TagsProps> = ({tag}) => {

    const juniorIcon = require("../img/icons/junior_icon.png");
    const midIcon = require("../img/icons/midlevel-icon.png");
    const seniorIcon = require("../img/icons/senior-icon.png");
    const leadIcon = require("../img/icons/lead-icon.png");

    if(tag === "top-candidate"){
        return(
        <div className="flex flex-row rounded-xl bg-yellow-400 font-medium p-2 px-3 text-sm mr-2">
            <span>🔥 Hot candidate</span>
        </div>
        );
    }

    if(tag === "s-1"){
        return(
        <div className="flex flex-row rounded-xl bg-white border-gray-2 font-medium p-2 px-3 text-sm mr-2">
            <img style={{width:20, marginRight:4}} src={juniorIcon} alt="stat icon" />
            <span>Junior</span>
        </div>)
    }

    if(tag === "s-2"){
        return(
        <div className="flex flex-row rounded-xl bg-white border-gray-2 font-medium p-2 px-3 text-sm mr-2">
            <img style={{width:20, marginRight:4}} src={midIcon} alt="stat icon" />
            <span>Mid level</span>
        </div>)
    }

    if(tag === "s-3"){
        return(
        <div className="flex flex-row rounded-xl bg-white border-gray-2 font-medium p-2 px-3 text-sm mr-2">
            <img style={{width:20, marginRight:4}} src={seniorIcon} alt="stat icon" />
            <span>Senior</span>
        </div>)
    }

    if(tag === "s-4"){
        return(
        <div className="flex flex-row rounded-xl bg-white border-gray-2 font-medium p-2 px-3 text-sm mr-2">
            <img style={{width:20, marginRight:4}} src={leadIcon} alt="stat icon" />
            <span>Lead</span>
        </div>)
    }




    return (<span>{tag}</span>)
};

export default Tag;