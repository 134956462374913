import React, { useEffect, useState } from 'react';
import { ComunaSidePanel } from "../components/ComunaSidePanel";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import { NavLink, Outlet, useLocation } from "react-router-dom";

export function LabelsCard() {
    const searchIcon = require("../img/icons/search-icon.png");
    const pencilIconBlack = require("../img/icons/pencil_black.png");

    const location = useLocation();

    const [labels, setLabels] = useState<{ id: string; text: string; isEditing: boolean; tempText: string }[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [addingNewLabel, setAddingNewLabel] = useState(false);
    const [newLabelText, setNewLabelText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const token = LocalStorageManager.getToken();
        ComunaStaffAPI.GetLabels(token).then(data => {
            if (data) {
                setLabels(data.map((label: { id: string; name: string }) => ({
                    id: label.id,
                    text: label.name,
                    isEditing: false,
                    tempText: label.name
                })));
            }
        }).catch(error => {
            alert(error);
        });
    }, [location.state?.refresh]);

    const HandleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredLabels = labels.filter(label => label.text.toLowerCase().includes(searchTerm.toLowerCase()));

    const HandleEditLabel = (index: number) => {
        setLabels(labels.map((label, i) => i === index ? { ...label, isEditing: true } : label));
    };

    const HandleSaveLabel = (index: number) => {
        const token = LocalStorageManager.getToken();
        const label = labels[index];
        
        ComunaStaffAPI.UpdateLabel(token, label.id, label.tempText).then(data => {
            setLabels(labels.map((l, i) => i === index ? { ...l, text: data.name, isEditing: false } : l));
        }).catch(error => {
            alert(error);
        });
    };

    const HandleCancelEdit = (index: number) => {
        setLabels(labels.map((label, i) => i === index ? { ...label, tempText: label.text, isEditing: false } : label));
    };

    const HandleChangeLabel = (index: number, newText: string) => {
        setLabels(labels.map((label, i) => i === index ? { ...label, tempText: newText } : label));
    };

    const HandleNewLabelTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewLabelText(event.target.value);
    };

    const HandleCreateLabel = () => {
        const token = LocalStorageManager.getToken();
        ComunaStaffAPI.CreateLabel(token, newLabelText).then(data => {
            if (data) {
                setLabels([...labels, { id: data.id, text: data.name, isEditing: false, tempText: data.name }]);
                setAddingNewLabel(false);
                setNewLabelText('');
            }
        }).catch(error => {
            alert(error);
        });
    };

    const RemoveLabelFromState = (id: string) => {
        setLabels(prevLabels => prevLabels.filter(label => label.id !== id));
    };

    return (
        <ComunaSidePanel return_to={"/requests"}>
            <div className="flex flex-col align-center">
                <h2 className="text-left py-3 border-b-4 border-gray-1 my-3">Internal Labels</h2>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div className="rounded-3xl border-2 border-gray-300 flex flex-row p-2 mr-4 my-3" style={{width:'max'}}>
                    <img src={searchIcon} style={{width:27}} alt="magnifying glass icon" />
                    <input 
                        onChange={HandleSearchChange}
                        className="w-full" 
                        type="text" 
                        placeholder="Search by labels.." />
                </div>
                <ul>
                    {filteredLabels.map((label, index) => (
                        <li key={index} className="flex justify-between items-center w-full p-2">
                            {label.isEditing ? (
                                <div className='flex flex-col bg-gray-1 rounded-xl w-full p-2'>
                                    <input
                                        className="comuna-input"
                                        value={label.tempText}
                                        onChange={(e) => HandleChangeLabel(index, e.target.value)}
                                    />
                                    <div className='flex flex-row py-3'>
                                        <NavLink className='flex-1 comuna-red-alt px-6 py-3 mx-3 rounded-full' to={"delete/" + label.id} >Delete</NavLink>
                                        <button className='flex-1 comuna-purple-bold px-6 py-3 mx-3 rounded-full' onClick={() => HandleCancelEdit(index)}>Cancel</button>
                                        <button className='flex-1 comuna-purple-light-bold px-6 py-3 mx-3 rounded-full font-medium' onClick={() => HandleSaveLabel(index)}>Save</button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <p className="bg-gray-1 rounded-xl px-2 py-3 text-left w-full" >{label.text}</p>
                                    <button className="icon mx-4" onClick={() => HandleEditLabel(index)}>
                                        <img src={pencilIconBlack} alt="icon of edit" style={{ width: 24 }} />
                                    </button>
                                </>
                            )}
                        </li>
                    ))}
                    {addingNewLabel && (
                        <li className="flex flex-col bg-gray-1 rounded-xl w-full p-2">
                            <input
                                className="comuna-input"
                                value={newLabelText}
                                onChange={HandleNewLabelTextChange}
                                placeholder="Enter new label"
                            />
                            <div className='flex flex-row py-3'>
                                <button className='flex-1 comuna-purple-bold px-6 py-3 mx-3 rounded-full' onClick={() => setAddingNewLabel(false)}>Cancel</button>
                                <button className='flex-1 comuna-purple-light-bold px-6 py-3 mx-3 rounded-full font-medium' onClick={HandleCreateLabel}>Create</button>
                            </div>
                        </li>

                    )}
                </ul>
                {!addingNewLabel && (
                    <button
                        onClick={() => setAddingNewLabel(true)}
                        className="flex-1 comuna-purple-light-bold px-6 py-3 mt-4 mx-3 rounded-full font-medium">
                        Add New Label
                    </button>
                )}
                <Outlet/>
            </div>
        </ComunaSidePanel>
    );
}
