import { useRouteLoaderData, useParams } from "react-router-dom"
import { useState, useEffect } from "react";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import { ComunaAPI } from "../managers/ComunaAPI";
import { CeleryTaskManager } from "../managers/CeleryTaskManager";

import { ComunaSidePanel } from "../components/ComunaSidePanel";

export function RequestQuestionsCard(){
    const pencilIconBlack = require("../img/icons/pencil_black.png");
    const copyIconWhite = require("../img/icons/copy_white.png");
    const copyIconBlack = require("../img/icons/copy_black.png");
    const refreshIconPurple = require("../img/icons/refresh_purple.png");

    const {resource_request_source}:any = useRouteLoaderData("resource_request_detail");

    //Interview questions
    const [interviewQuestions, setInterviewQuestions] = useState<any[]>([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [clipboardBtnText, setClipboardBtnText] = useState('Copy questions');

    useEffect(() => {
        FetchInterviewQuestions();
	}, []);

    async function FetchInterviewQuestions() {
        const token = await LocalStorageManager.getToken();
        const questions = await ComunaAPI.GetInterviewQuestions(token, resource_request_source.id);
        
        if(questions != null)
            setInterviewQuestions(questions.map((q:any) => ({
                ...q, 
                isEditing: false, 
                tempValue: q.question
            })));
    }

    async function HandleGenerateQuestions() {
        setInterviewQuestions([]);
        setIsGenerating(true);
    
        const token = await LocalStorageManager.getToken();
        // Start the task and get the task ID from the response
        try {
            const response = await ComunaStaffAPI.GenerateInterviewQuestions(token, resource_request_source.id);
            if (response && response.task_id) {
                CeleryTaskManager.pollTaskStatus(
                    response.task_id, 
                    token, 
                    (result: any) => {
                        // Success handler
                        if (result && result.length > 0) {
                            setInterviewQuestions(result.map((q: any) => ({
                                ...q, 
                                isEditing: false, 
                                tempValue: q.question
                            })));
                        } else {
                            alert("No questions were generated. Please try again.");
                        }
                        setIsGenerating(false);
                    },
                    (error: any) => {
                        // Failure handler
                        console.error('Failed to generate interview questions:', error);
                        alert("Failed to generate interview questions. Please try again.");
                        setIsGenerating(false);
                    }
                );
            } else {
                throw new Error("Task initiation failed, no task ID received.");
            }
        } catch (error) {
            console.error('Error during question generation task initiation:', error);
            alert("Failed to initiate question generation. Please check the console for more details.");
            setIsGenerating(false);
        }
    }    

    async function CopyQuestionsToClipboard(){
        if (!interviewQuestions) {
            console.error('Interview questions are null or undefined.');
            return;
        }
        const questionsText = interviewQuestions.map((questionObj: any) => questionObj.question).join('\n');
        
        try {
            await navigator.clipboard.writeText(questionsText);
            setClipboardBtnText('Copied!');
            setTimeout(() => setClipboardBtnText('Copy questions'), 2000);
        } catch (err) {
            console.error('Failed to copy!', err);
        }
    }

    function HandleEditQuestion(index: number) {
        setInterviewQuestions(interviewQuestions.map((q, i) => {
            return i === index ? { ...q, isEditing: true } : q;
        }));
    }
    
    async function HandleSaveQuestion(index: number) {
        const updatedQuestion = interviewQuestions[index];

        const token = await LocalStorageManager.getToken();
        console.log(updatedQuestion);
        const answer = await ComunaStaffAPI.UpdateInterviewQuestions(token, resource_request_source.id, updatedQuestion.id, updatedQuestion.tempValue);
        
        if (answer) {
            setInterviewQuestions(interviewQuestions.map((q, i) => {
                return i === index ? { ...q, question: q.tempValue, isEditing: false } : q;
            }));
        } else {
            console.error('Failed to save the updated question. Please try again.');
        }
    }
    
    function HandleCancelEdit(index: number) {
        setInterviewQuestions(interviewQuestions.map((q, i) => {
            return i === index ? { ...q, tempValue: q.question, isEditing: false } : q;
        }));
    }
    
    function HandleChangeQuestion(index: number, newValue: string) {
        setInterviewQuestions(interviewQuestions.map((q, i) => {
            return i === index ? { ...q, tempValue: newValue } : q;
        }));
    }
    
    function HandleCopyQuestion(question: string) {
        navigator.clipboard.writeText(question).then(() => {
            // Optionally, trigger a notification that copying was successful
            console.log('Question copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }


    return(
    <ComunaSidePanel return_to={"/requests/"+resource_request_source.id} >
        <div className="flex flex-row justify-between items-center">
            <h2 className="text-left mt-6">Interview Questions</h2>
            
            {interviewQuestions.length > 0 && (
                <div className="flex flex-row">
                {/** GENERATE AGAIN BUTTON
                    <button 
                        onClick={HandleGenerateQuestions} 
                        className="comuna-secondary-button sm mt-4 mx-2"
                        disabled={isGenerating}
                    >
                        <img
                            src={refreshIconPurple}
                            alt="refresh"
                            style={{width:24, marginRight:8}} 
                        />
                        {isGenerating ? 'Generating...' : 'Generate again'}
                    </button>
                */}
                    <button 
                        onClick={CopyQuestionsToClipboard} 
                        className="comuna-primary-button sm mt-4 mx-2"
                    >
                        <img
                            src={copyIconWhite}
                            alt="copy"
                            style={{width:24, height:24, marginRight:8}} 
                        /> 
                        {clipboardBtnText}
                    </button>
                </div>)
            }
        </div>
        {interviewQuestions.length > 0 ? (
            <div className="flex flex-row pt-6">
                <ul className="pl-5 w-full">
                    {interviewQuestions.map((question, index) => (
                        <li key={index} className="mb-2">
                            <div className={`relative flex items-center bg-gray-1 rounded-xl ${question.isEditing ? 'p-2' : 'p-6'}`}>
                                {question.isEditing ? (
                                    <textarea
                                        className="comuna-input"
                                        id={"Interview-question" + index}
                                        value={question.tempValue}
                                        onChange={(e) => HandleChangeQuestion(index, e.target.value)}
                                        rows={1}
                                    />
                                ) : (
                                    <p className="text-left w-full" >{question.tempValue}</p>
                                )}
                                {!question.isEditing && (
                                    <div className="flex items-center pr-2">
                                        <button className="icon px-6" onClick={() => HandleCopyQuestion(question.question)}>
                                            <img src={copyIconBlack} alt="icon of copy" style={{ width: 24 }} />
                                        </button>
                                        <button className="icon" onClick={() => HandleEditQuestion(index)}>
                                            <img src={pencilIconBlack} alt="icon of edit" style={{ width: 24 }} />
                                        </button>
                                    </div>
                                )}
                                {question.isEditing && (
                                    <div className="flex items-center pr-2">
                                        <button
                                            className="comuna-purple-bold px-6 py-3 rounded-full font-medium mx-4"
                                            onClick={() => HandleCancelEdit(index)}>
                                                Cancel
                                        </button>
                                        <button 
                                            className="comuna-purple-light-bold px-6 py-3 rounded-full font-medium"
                                            onClick={() => HandleSaveQuestion(index)}>
                                                Save
                                        </button>
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <>
                <p className="text-left" style={{marginTop:10, marginBottom:2}}>No interview questions available.</p>
                <button
                    onClick={HandleGenerateQuestions}
                    className="comuna-primary-button sm mt-4"
                    disabled={isGenerating}>
                    {isGenerating ? 'Generating...' : 'Generate Interview Questions'}
                </button>
            </>
        )}
    </ComunaSidePanel>)
}