import { useEffect, useState } from "react";

import { role } from "../types/roleTypes";

import { ComunaAPI } from "../managers/ComunaAPI";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ValidateRoles } from "../utils/roleValidator";

import { Loader } from "../components/loader";

import { RoleForm } from "./ResourceRequest/RoleForm_v1.1";
import { BenefitsForm } from "../components/ResourceRequest/BenefitsForm_v1.1";

import { ResourceRequestOverviewTemplate } from "../config";
import ClientCallPopup from "./ResourceRequest/ClientCallPopup";
import { findClosestDate } from "../utils/dateUtils";
import PricingTable from "./ResourceRequest/PricingTable";
const xIcon = require("../img/icons/x.png");
const checkMark = require("../img/check_mark.png");

interface NewRequestFormProps {
	showForm: boolean;
	onClose: () => void;
}

export function NewRequestForm({ showForm, onClose }: NewRequestFormProps) {
	const [showPopup, setShowPopup] = useState(false);

	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [lastCreatedRole, setLastCreatedRole] = useState<any>(null);

	const default_role: role = {
		id: -1,
		roleName: "",
		roleOrganization: null,
		roleQuantity: 1,
		roleStartDate: "",
		roleDescription: ResourceRequestOverviewTemplate,
		roleSeniority: "junior",
		roleModality: "remote",
		roleLocation: "",
		roleSkills: [],
		roleLabels: [],
		roleDetails: "",
		benefits: [],
		nameCheck: true,
		organizationCheck: true,
		descriptionCheck: true,
		skillsCheck: true,
		labelsCheck: true,
		dateGood: true,
		good: true,
		interviewWeights: {
			"application": 1,
			"communication": 1,
			"culture": 1,
			"problem_solving": 1,
			"technical": 1
		},
	};

	//array to save the Roles being requested
	const [rolesInfo, setRolesInfo] = useState<role[]>([default_role]);

	//arrays with information for some fields
	const [availableSkills, setAvailableSkills] = useState([]);
	const [availableLabels, setAvailableLabels] = useState([]);
	const [availableOrganizations, setAvailableOrganizations] = useState<any[]>([]);

	const [roleSkillsOptions, setRoleSkillsOptions] = useState<any>([]);
	const [roleLabelsOptions, setRoleLabelsOptions] = useState<any>([]);
	const [roleOrganizationsOptions, setRoleOrganizationsOptions] = useState<any>([]);

	const [benefits, setBenefits] = useState<number[]>([]);
	const [selectedPlan, setSelectedPlan] = useState<string>("Essentials");
	const [benefitsPlans, setBenefitsPlans] = useState<any>(null);

	const [roleRecs, setRoleRecs] = useState([]);

	//lets load the available skills and benefits
	useEffect(() => {
		LoadSkillsAndBenefits();
	}, []);

	//once we have role skills loaded we check if we are duplicating a request
	//we should check only if we have roleSkillsOptions loaded
	useEffect(() => {
		if (roleSkillsOptions.length > 0) {
			GetDuplicate();
		}
	}, [roleSkillsOptions]);

	async function GetDuplicate() {
		var duplicate = await window.localStorage.getItem("duplicate");
		if (duplicate) {
			var r = JSON.parse(duplicate);

			const role: role = {
				id: -1,
				roleName: r.role_name,
				organizationCheck: true,
				roleOrganization: r.organization_profile,
				roleQuantity: r.quantity,
				roleStartDate: r.start_date,
				roleDescription: r.position_overview,
				roleSeniority: r.seniority,
				roleModality: r.modality,
				roleLocation: r.location,
				roleDetails: r.additional_details,
				roleSkills: [],
				roleLabels: [],
				benefits: [],
				nameCheck: true,
				descriptionCheck: true,
				skillsCheck: true,
				labelsCheck: true,
				dateGood: true,
				good: true,
				interviewWeights: r.interview_weights,
			};

			const existingSkills: any[] = [];

			r.skills.map((skill_id: any) => {
				roleSkillsOptions.map((option: any) => {
					if (option.value === skill_id) {
						existingSkills.push(option);
					}
				});
			});
			role.roleSkills = existingSkills;

			const existingLabels: any[] = [];

			r.skills.map((label_id: any) => {
				roleLabelsOptions.map((option: any) => {
					if (option.value === label_id) {
						existingLabels.push(option);
					}
				});
			});
			role.roleLabels = existingLabels;


			setRolesInfo([role]);
			window.localStorage.removeItem("duplicate");
		}
	}

	function compareStrings(a: string, b: string) {
		// Assuming you want case-insensitive comparison
		a = a.toLowerCase();
		b = b.toLowerCase();

		return a < b ? -1 : a > b ? 1 : 0;
	}

	async function LoadSkillsAndBenefits() {
		var token = await LocalStorageManager.getToken();
		const fields = await ComunaAPI.getResReqFields(token); //Get Resource Request Fields for form

		if (fields != null) {
			setAvailableSkills(fields.skills);

			var newSkillOptions: any[] = [];
			fields.skills.map((item: any) => {
				newSkillOptions.push({
					label: item.name,
					value: item.id,
				});
			});
			setRoleSkillsOptions(newSkillOptions);

			setAvailableLabels(fields.labels);
			var newLabelOptions: any[] = [];
			fields.labels.map((item: any) => {
				newLabelOptions.push({
					label: item.name,
					value: item.id,
				});
			});
			setRoleLabelsOptions(newLabelOptions);

			setAvailableOrganizations(fields.organizations);
			var newOrganizationOptions: any[] = [];
			fields.organizations.map((item: any) => {
				newOrganizationOptions.push({
					label: item.name,
					value: item.id,
				});
			});
			setRoleOrganizationsOptions(newOrganizationOptions);

			const roleRecsOptions: any = [];
			//sort the skills alphabetically
			var sortedRecommendations = fields.role_recommendations.sort(function (
				a: any,
				b: any
			) {
				return compareStrings(a.role_name, b.role_name);
			});
			console.log("sorted role reccommendations: ", sortedRecommendations);

			if (sortedRecommendations.length > 0) {
				sortedRecommendations.map((role: any) => {
					roleRecsOptions.push({
						value: role.role_name,
						label: role.role_name,
					});
				});
				setRoleRecs(roleRecsOptions);
			}

			const default_benefits: any = [];
			fields.benefits.map((benefit: any) => {
				if (!benefit.editable) {
					default_benefits.push(benefit.id);
				}
			});
			setBenefits(default_benefits);
			setBenefitsPlans(fields.benefits_plans);
		}
	}

	const handlePlanSelect = (plan: string, benefits: number[]) => {
		setSelectedPlan(plan);
		setBenefits(benefits);
		console.log("Selected Plan and Benefits: ", plan, benefits);
	};


	function close() {
		console.log("resetting saved role info");
		LocalStorageManager.setRoleInfo("");
		setRolesInfo([default_role]);
		LocalStorageManager.setBenefitsInfo("");
		window.history.back();
		onClose();
	}

	function prevStep() {
		if (step === 1) {
			close();
		} else {
			setStep(step - 1);
		}
	}

	function nextStep() {
		if (step === 1) {
			//validate the form
			if (ValidateRoles(rolesInfo, setRolesInfo, true)) {
				//if valid, go to next step
				setStep(step + 1);
				window.scrollTo(0, 0);
			}
			return;
		}
		if (step === 2) {
			submitForm();
		} else {
			setStep(step + 1);
			window.scrollTo(0, 0);
		}
	}

	async function submitForm() {
		var success = true;
		setLoading(true);
		var token = await LocalStorageManager.getToken();

		//finally create the resource requests
		for (const role of rolesInfo) {
			var roleResult = await ComunaStaffAPI.SendNewResourceRequest(
				token,
				role,
				benefits
			);
			if (roleResult === null) {
				success = false;
			} else {
				console.log("resetting saved role info", roleResult);
				LocalStorageManager.setRoleInfo("");
				LocalStorageManager.setBenefitsInfo("");

				setLastCreatedRole(roleResult);
			}
		}
		//and notify success or failure
		if (success) {
			setSuccess(true); //this will make the success message appear
		} else {
			alert("There was a problem with your request, please try again.");
		}
		setLoading(false);
	}

	if (showForm === false) {
		return null;
	}

	if (success && lastCreatedRole != null) {
		return (
			<div className="comuna-popup">
				<div
					className="bg-white rounded-xl p-12 mt-24 relative fade-in text-center"
					style={{ maxWidth: 512, width: "90%" }}>
					<img src={checkMark} alt="" style={{ width: 200, margin: "auto" }} />
					<h1 className="text-3xl font-bold w-full mt-8">Job Created!</h1>
					<p className="mt-4 text-black">
						Your listing has been created! We’ll follow up with next steps soon.
					</p>
					{/** deberia ser un link a la resource request creada */}
					<a
						href={"/requests/" + lastCreatedRole.id}
						className="comuna-primary-button font-medium mt-8">
						Got It
					</a>
				</div>
			</div>
		);
	}

	function handleResourceRequestsReceived(resources: any[], client_call_id: number) {
		setShowPopup(false);
		const newRolesInfo = resources.map(resource => ({
			id: resource.id,
			benefits: resource.benefits.map((benefit: any) => benefit.id),
			roleName: resource.role_name,
			roleOrganization: roleOrganizationsOptions.find((org: any) => org.value === resource.organization_profile),
			roleQuantity: resource.quantity,
			roleStartDate: findClosestDate(resource.start_date),
			roleDescription: resource.position_overview,
			roleSeniority: resource.seniority,
			roleModality: resource.modality,
			roleLocation: resource.not_wanted_locations,  // Assuming this is the location field
			roleSkills: resource.skills.map((skill: any) => ({
				label: skill.name,
				value: skill.id
			})),
			roleLabels: [],
			roleDetails: resource.additional_details,
			nameCheck: true,
			organizationCheck: true,
			descriptionCheck: true,
			skillsCheck: true,
			labelsCheck: true,
			dateGood: true,
			good: true,
			interviewWeights: {
				"application": 1,
				"communication": 1,
				"culture": 1,
				"problem_solving": 1,
				"technical": 1
			},
			clientCall: client_call_id
		}));
		var stringifiedRoles = JSON.stringify(newRolesInfo);
		LocalStorageManager.setRoleInfo(stringifiedRoles);
		setRolesInfo(newRolesInfo);

		const benefits = resources.flatMap(resource => resource.benefits.map((benefit: any) => benefit.id));
		LocalStorageManager.setBenefitsInfo(JSON.stringify(benefits));
	}
	return (
		<div className="comuna-popup">
			{showPopup && (
				<ClientCallPopup
					isVisible={showPopup}
					onClose={() => setShowPopup(false)}
					onResourcesReceived={handleResourceRequestsReceived}
					organizations={roleOrganizationsOptions}
				/>
			)}
			<div
				className="bg-white rounded-xl p-12 relative fade-in"
				style={{ maxWidth: 1014, width: "90%" }}>
				{/** Close Button */}
				<button
					onClick={close}
					className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					<img src={xIcon} alt="icon of an x" />
				</button>

				<button
					className="comuna-primary-button font-medium"
					onClick={() => setShowPopup(true)}
					style={{ margin: '20px' }}
				>
					Generate Roles with AI
				</button>

				<h1 className="text-3xl font-bold w-full">New Request</h1>

				{step === 1 && (
					<div>
						<p className="mt-8 font-bold text-lg text-black">Role Details</p>
						<p className="mt-2 text-black">What will they be doing</p>
					</div>
				)}

				{step === 2 && (
					<div>
						<p className="mt-8 font-bold text-lg text-black">Perks <span className="mt-2 font-normal text-black"> for your new team member/s</span> </p>
						<p className="mt-4 text-gray-500 text-sm">
							*Prices may vary depending on the contractor’s country.
						</p>
					</div>
				)}

				{step === 3 && (
					<div>
						<p className="mt-8 font-bold text-lg text-black">Summary</p>
						<p className="mt-2 text-black">You are almost done!</p>
					</div>
				)}

				<div className="flex flex-row my-4">
					<div className={"comuna-step-indicator completed"}></div>
					<div
						className={
							"comuna-step-indicator mx-4 " + (step > 1 ? "completed" : "")
						}></div>
				</div>

				{step === 1 && (
					<RoleForm
						roles={rolesInfo}
						setRoles={setRolesInfo}
						skills={roleSkillsOptions}
						labels={roleLabelsOptions}
						roleRecs={roleRecs}
						organizations={roleOrganizationsOptions}
					/>
				)}

				{step === 2 && (
					<PricingTable
						planSelected={selectedPlan}
						categories={benefitsPlans}
						onPlanSelect={handlePlanSelect}
					/>
				)}

				<div className="flex flex-row mt-8">
					{!loading && (
						<button
							onClick={prevStep}
							className="comuna-secondary-button border-none bg-purple-light font-medium mr-4">
							Back
						</button>
					)}
					{loading && (
						<button disabled className="comuna-disabled-button mr-4">
							Back
						</button>
					)}

					<button
						onClick={nextStep}
						className="comuna-primary-button font-medium">
						{loading ? <Loader /> : "Continue"}
					</button>
				</div>
			</div>
		</div>
	);
}
