import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { DateTime } from "luxon";
import { FormatDateToLocalString } from "../utils/formaters";
import DatePicker from "react-datepicker";
import { Loader } from "./loader";
import "react-datepicker/dist/react-datepicker.css";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";

interface ApplicationInterviewFormProps {
    application: any;
}



const ApplicationInterviewForm : React.FC<ApplicationInterviewFormProps> = ({application}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [parsedAvailableDates, setParsedAvailableDates] = useState<any[]>([]);
    const [showCallLinkForm, setShowCallLinkForm] = useState<boolean>(false);

    const [callLink, setCallLink] = useState<string>("");
    const [interviewDate, setInterviewDate] = useState<Date>(new Date());

    useEffect(() => {
		if (application !== undefined) {
			if (application && application.interview_available_dates) {
                ParseAvailableDates(application.interview_available_dates);
            }
            if(application.interview_accepted_date?.length>0){
                const utcDate = DateTime.fromISO(application.interview_accepted_date.replace(" ", "T"), { zone: "utc" });
                const localDate = utcDate.setZone(DateTime.local().zoneName)
                setInterviewDate(localDate.toJSDate());
            }
		}
	}, []);

    function ParseAvailableDates(dates: string) {
		if (dates === null || dates === "") return;

		var parsedDates: string[] = dates.split(",");
		var locallyParsedDates: string[] = [];
		parsedDates.forEach((date) => {
			if (date !== "") {
				var reParsed: string = FormatDateToLocalString(date);
				locallyParsedDates.push(reParsed);
			}
		});
		setParsedAvailableDates(locallyParsedDates);
	}

    async function submitForm(){
        setLoading(true);
        const token = await LocalStorageManager.getToken();
        const result = await ComunaStaffAPI.SendInterviewInvitation(token, application.id, interviewDate, callLink);
        if(result=="OK"){
            alert("Interview invitation has been sent");
            window.location.reload();
        }else{
            alert("Error sending interview invitation: "+result);
            setLoading(false);
        }
    }

    //applications with an already sended proposal (state 4 or greater, dot need anything interview related)
    if(application.state>=4){
        return null;
    }

    //if this application has no available dates nor a calendar link, there is nothing for us to do here.
    if(application.interview_available_dates==null && application.interview_calendar_link==null){
        return null;
    }

    //if we have a call link that means this interview has been scheduled, we want to display that information.
    if(application.interview_call_link!=null){
        return(
            <div className="comuna-green rounded-xl p-4">
                <h4 className="font-bold text-2xl">Interview Scheduled</h4>
                <p className="comuna-green"> Interview for this application has been schedule at <b>{FormatDateToLocalString(application.interview_accepted_date)}</b> <br/>
                The link for the call is: <b><a href={application.interview_call_link}>{application.interview_call_link}</a></b></p>
            </div>
        )
    }


    const Message = ()=>{
        //if we have a set of available dates, and no accepted date we want to display that information: 
        if(application.interview_available_dates && application.interview_accepted_date==null){
            return(
                <div>
                <h4 className="font-bold comuna-purple">Interview Requested</h4>
                <p className="comuna-purple">
                    The Client has sent this dates to the candidate for an interview:
                    {parsedAvailableDates.length > 0 &&
                        parsedAvailableDates.map((date: string) => {
                            return (
                                <b>
                                    <br />
                                    {"  " + date + "  "}
                                </b>
                            );
                        })}
                    <br />
                    and we are waiting for the candidate to respond.
                </p>
            </div>
            )
        }

        //if we have a calendar link, we need to display that information.
        if(application.interview_calendar_link?.length>3){
            return(
                <div>
                <h4 className="font-bold comuna-purple">Interview Requested with Link</h4>
                <p className="comuna-purple">
                    The Client has sent this link to setup the meeting with the candidate:
                    <br />
                    <b>
                        <a href={application.interview_calendar_link} target="blank">
                        {application.interview_calendar_link}
                        </a>
                    </b>
                    <br />
                    Use the link to find a time for the interview and create the calendar link here.
                </p>
            </div>
            )
        }

        //if the contractor rejected all the times for the interview we want to display that.
        if(application.interview_accepted_date?.length>0 && application.interview_accepted_date=="rejected"){
            return (
                <div>
                <h4 className="font-bold comuna-purple">Interview Rejected by Contractor</h4>
                <p className="comuna-purple">
                    The contractor has rejected all the dates sent by the client, <br/>
                    we need to set up a new time manually with both parties: <br />
                </p>
                </div>
            )
        }

        //if the contractor accepted one of the dates for the interview we want to display that.
        if(application.interview_accepted_date?.length>0){
            return(
                <div>
                <h4 className="font-bold comuna-purple">Interview Accepted by Contractor</h4>
                <p className="comuna-purple">
                    The contractor has agreed with one of the dates sent by the client: <br />
                    <b>
                    {FormatDateToLocalString(application.interview_accepted_date)}
                    </b>
                    <br />
                    It is time to schedule the interview and create a link for the call
                </p>
                </div>
            )
         }

         return <></>;
    }
    
    return (
        <div className="flex flex-col bg-comuna-light p-4 rounded-xl mx-8 mt-10">
            <Message />
            <button className="comuna-primary-button mt-4" onClick={()=>{setShowCallLinkForm(true)}}>Add the Link for the Call</button>
            {showCallLinkForm && createPortal(
                <div className="comuna-popup">
                    <div className="comuna-card p-12">
                        <h1 className="text-2xl comuna-purple font-bold text-center">Confirm the Call Link and Interview Date</h1>
                        <p className="text-xl my-4 text-center" style={{maxWidth:"24em"}}>Use this form to confirm the date, time and Link of the meeting, when saving this form the Client and the Contractor will get an email invitation.</p>
                        
                        <b className="comuna-input-label">Date and Time</b>
                        <DatePicker
							wrapperClassName="w-full"
							showTimeSelect
							selected={interviewDate}
							dateFormat="MMMM d, yyyy h:mm aa"
							placeholderText="Select a date and time"
							onSelect={(date: Date) => { setInterviewDate(date)}}
							onChange={(date: Date) => { setInterviewDate(date)}}
							className="comuna-input"
						/>

                        <b className="comuna-input-label">Link for the Call</b>
                        <input 
                            type="text" 
                            className="comuna-input"  
                            placeholder="https://website.com/xyz"
                            value={callLink}
                            onChange={(e)=>{setCallLink(e.target.value)}} />

                        <button 
                            onClick={submitForm}
                            className={"mt-4 "+(callLink.length>3?"comuna-primary-button":"comuna-disabled-button") }
                            disabled={callLink.length<4}> 
                                {loading ? <Loader/> : "Confirm Date, time and Link "}
                        </button>
                        <button className="comuna-secondary-button mt-4" onClick={()=>{setShowCallLinkForm(false);}}>Cancel</button>
                    </div>
                </div>, document.body)}
        </div>
    );
}


export default ApplicationInterviewForm;