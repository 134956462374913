import React, { useEffect, useState } from "react";

interface ResourceRequestItemProps {
	resourceRequest: any;
	selected: boolean;
	onSelectResReq: (resourceRequest: any) => void;
	notifications: any[];
}

const ResourceRequestItem: React.FC<ResourceRequestItemProps> = ({
	resourceRequest,
	onSelectResReq,
	selected,
	notifications,
}) => {
	const defaultProfilePicture = require("../../img/icons/emptyAvatar.png");

	const [notifCount, setNotifCount] = useState(0);

	useEffect(() => {
		var count = 0;
		notifications.forEach((notification) => {
			resourceRequest.applications.forEach((application: any) => {
				if (
					notification.target === application &&
					notification.cleared === false
				) {
					count += notification.counter;
				}
			});
		});

		setNotifCount(count);
	}, [notifications, resourceRequest.applications]);

	return (
		<div
			onClick={() => {
				onSelectResReq(resourceRequest);
			}}
			className={
				"flex flex-row w-full h-fit border-b border-gray-100 py-3 pl-6 pr-4 cursor-pointer " +
				(selected ? " bg-gray-200" : "")
			}>
			{resourceRequest.organization_profile.picture ? (
				<img
					alt="contractor"
					style={{ width: 108, height: 54 }}
					className="rounded-full"
					src={resourceRequest.organization_profile.picture}
					onError={({ currentTarget }) => {
						console.log("error", currentTarget);
						currentTarget.onerror = null;
						currentTarget.src = defaultProfilePicture;
					}}
				/>
			) : (
				<div
					style={{ width: 108, height: 54 }}
					className="bg-gray-400 rounded-full font-bold text-white flex justify-center items-center">
					{resourceRequest.organization_profile.name[0]}
				</div>
			)}
			<div className="flex flex-col w-full h-full ml-4 justify-center">
				<b className="w-full h-fit text-lg font-semibold text-black">
					{resourceRequest.organization_profile.name}
				</b>
				<p className="w-full h-fit text-sm font-medium text-gray-700">
					{resourceRequest.role_name}
				</p>
			</div>
			<div className="flex flex-col w-7/12 h-full ml-4 justify-center">
				{notifCount > 0 && (
					<b className="w-6 h-6 text-white bg-comuna-purple rounded-full text-center px-1 pb-1">
						{notifCount}
					</b>
				)}
				<b className="w-full h-fit text-md text-black font-normal">
					{resourceRequest.applications.length + " app"}
				</b>
			</div>
		</div>
	);
};

export default ResourceRequestItem;
