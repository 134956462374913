import { useContext, useState, useEffect, Fragment } from "react";
import { ComunaLogo } from "../components/comunaLogo";
import { UserContext } from "../Providers/UserContext";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import { NavLink, useLoaderData, Link, useNavigate, Outlet } from "react-router-dom";

import Fuse from 'fuse.js'

import SearchForComunaAgent from "../components/SearchForComunaAgent";
import { NewRequestForm } from "../components/NewRequestForm_v1.1";

const searchIcon = require("../img/icons/search-icon.png");
const plusIcon = require("../img/icons/plus-purple.png");
const officeIcon = require("../img/icons/office.png");
const labelIcon = require("../img/icons/label.png");

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const requests = await ComunaStaffAPI.GetResourceRequests(token, "mine", false);
	return { requests };
}

const fuseOptions = {
	keys: [
		"role_name",
		"organization_profile.name",
		"comuna_agent.name"
	],
	includeScore: true
}

export function RequestsView() {

	const Navigate = useNavigate();

	const { user } = useContext<any>(UserContext);
	const { requests }: any = useLoaderData(); console.log("REQUESTS: ", requests);
	const [availableRequests, setAvailableRequests]	= useState(requests);
	const [filterRequests, setFilterRequests] = useState(requests);
	const [selectedRequest, setSelectedRequest] = useState<any>(false);

	const [filter, setFilter] = useState("mine"); //new or mine
	const [archived, setArchived] = useState(false);//true or false for showing archived stuff
	const [query, setQuery] = useState(""); //search query
	const [assigning, setAssigning] = useState(false); //are we assigning something?

	const [labels, setLabels] = useState<any[]>([]); // Store label options
	const [selectedLabels, setSelectedLabels] = useState<any[]>([]); // Store user-selected labels	
	
	const [showNewForm, setShowNewForm] = useState(false);

	const tabClassName = "bg-gray-1 px-4 py-2 rounded-xl font-medium mx-2"
	const tabClassNameSelected = "comuna-purple-bold px-4 py-2 rounded-xl font-medium mx-2"


	useEffect(()=>{
		UpdateRequests();
	}, [filter, archived, selectedLabels])

	useEffect(()=>{
		if(query===""){
			UpdateRequests();
		}
	}, [query])

	useEffect(() => {
		async function fetchLabels() {
		  var token = await LocalStorageManager.getToken();
		  const labelData = await ComunaStaffAPI.GetLabels(token);
		  setLabels(labelData);
		}
		fetchLabels();
	  }, []);

	async function UpdateRequests() {
		var token = await LocalStorageManager.getToken();
		const requests = await ComunaStaffAPI.GetResourceRequests(token, filter, archived);
		setAvailableRequests(requests);
		
		let filteredRequests = requests;
		
		// Apply text search if query is not empty
		if (query !== "") {
			const fuse = new Fuse(requests, fuseOptions);
			const searchResults = fuse.search(query);
			filteredRequests = searchResults.map((result: any) => result.item);
		}

		if (selectedLabels.length > 0) {
			filteredRequests = filteredRequests.filter((request: any) =>
			selectedLabels.every(label => 
				request.labels && request.labels.some((reqLabel: any) => reqLabel.id === label.id))
			);
		}
		
		setFilterRequests(filteredRequests);
	}

	function SearchQuery(event:any){
		if (event.key === 'Enter') {
			UpdateRequests();
		}
	}

	function AssignButton(props:any){
		if(user.groups.includes("staff_lead")){
			return(
				<button
				onClick={(event)=>{
					event.preventDefault();
					event.stopPropagation();
					setAssigning(true)
					setSelectedRequest(props.item);
				}} 
				className="comuna-secondary-button sm font-bold">Assign</button>
				)
		}
		else{
			return (
				<span>None</span>
			)
		}
	}

	function RequestRow(props:any){

		if(props.items.length === 0){
			return (
				<tbody style={{
					overflowY: "auto",
					height: "100%",
					paddingBottom: 120, 
					paddingLeft: 20
				}} >
					<tr className="bg-gray-1 rounded-xl" >
						<td className="text-center ml-6" colSpan={7} >No requests found</td>
					</tr>
				</tbody>
			)
		}

		return(
			<tbody style={{
				overflowY: "auto",
				height: "100%",
				paddingBottom: 120
			}} >
				{props.items && props.items.length>0 && props.items.map((item:any, index:number)=>{
					return(
						<Fragment>
							<tr className="text-black cursor-pointer hover:bg-gray-1"
								onClick={()=>{ Navigate("/requests/"+item.id) }}
								style={!item.labels || item.labels.length <= 0 ? { borderBottom: "1px solid #e0e0e0" } : {}}
								key={index}>
								<td className="pl-6">
									<Link to={"/requests/"+item.id} className="cursor-pointer hover:text-blue-500 table-row w-1/6">
										<b>{item.role_name}</b>
									</Link>
								</td>
								
								<td>
									<div className={`flex flex-row items-center justify-start ${item.labels && item.labels.length > 0 ? 'py-6' : 'pt-5 pb-3'}`}>
									{
										item.organization_profile.picture ? 
										<img style={{width:40, height:40, overflow:"hidden", borderRadius:12}} src={item.organization_profile.picture} alt="" />
										:
										<div className="bg-comuna-purple-transparent flex flex-row justify-center items-center"  style={{width:40, height:40, overflow:"hidden", borderRadius:12}}>
											<img src={officeIcon} style={{width:24, height:24}} alt="" />
										</div>
									}
									{item.organization_profile && <span className="ml-2 text-black">{item.organization_profile.name}</span>}
									</div>
								</td>

								<td>
									<div className={"flex flex-row w-fit h-fit cursor-pointer"} style={{borderRadius:9,overflow:"hidden"}}>
										<label className="bg-screening px-3 py-2">{item.applications_count.screening}</label>
										<label className="bg-cultural px-3 py-2">{item.applications_count.cultural}</label>
										<label className="bg-technical px-3 py-2">{item.applications_count.technical}</label>
										<label className="bg-partner px-3 py-2">{item.applications_count.interviewing}</label>
										<label className="bg-decision px-3 py-2">{item.applications_count.offer_sent+item.applications_count.accepted+item.applications_count.rejected}</label>
									</div>
								</td>

								<td className="text-left">{item.date_created? item.date_created.split("T")[0]:"--/--/----"}</td>

								<td className="text-left">{item.start_date? item.start_date.split("T")[0]:"--/--/----"}</td>

								<td className="text-left">{item.last_seen_date? item.last_seen_date.split("T")[0]:"---"}</td>

								<td className="text-right pr-6 flex justify-end pt-4"> 
								{item.comuna_agent === null ? 
									<AssignButton item={item}/>
									:
									<div style={{position:"relative", top: 20}}>
										{item.comuna_agent && <span className="ml-2">{item.comuna_agent.name}</span>}
									</div>
								}
								</td>

							</tr>
							{item.labels && item.labels.length > 0 && (
								<tr style={{borderBottom: "1px solid #e0e0e0"}}>
									<td colSpan={7}>
										<div className="flex flex-row px-6 pb-3">
											{item.labels.map((label: any) => (
												<div className="px-3 py-1 m-1" key={label.name} style={{ border: "1px groove #e0e0e0", borderRadius: "5px" }}>
													{label.name}
												</div>
											))}
										</div>
									</td>
								</tr>
							)}
						</Fragment>
					)
				})}
			</tbody>
		)
	}

	return (
		<div className="w-full h-full px-6 flex flex-col">
    		<div className="header flex justify-between items-start mt-6 mb-4">
				<h1 className="text-3xl font-bold">Requests</h1>
				<div className="flex flex-row">
					<Link
						to="/requests/?new=true"
						className="comuna-circle-button-alt mx-2"
						onClick={() => {
								setShowNewForm(true);
							}}>
						<img src={plusIcon} style={{width:24, height:24}} alt="" />
					</Link>

					<NavLink to="labels" className="comuna-circle-button-alt mx-2">
						<img src={labelIcon} style={{width:24, height:24}} alt="" />
					</NavLink>
				</div>
			</div>
			
			<div className="comuna-card w-full  pt-4" style={{maxHeight:"86%"}}>
				
				<div className="flex flex-col border-b-2 pb-4">
					<div className="flex flex-row items-center">
						<span className="color-gray-1 text-right mr-2" style={{width:200}}>Filter by</span>

						<button className={filter === "new" ? tabClassNameSelected : tabClassName } onClick={()=>{setFilter("new")}}>New</button>
						<button className={filter === "mine" ? tabClassNameSelected : tabClassName } onClick={()=>{setFilter("mine")}}>Mine</button>
						<button className={filter === "all" ? tabClassNameSelected : tabClassName } onClick={()=>{setFilter("all")}}>All</button>

						<div className="w-1 h-6 bg-gray-300 mx-4"></div>

						<button className={archived? tabClassNameSelected : tabClassName } onClick={()=>{setArchived(!archived)}}>Archived</button>

						<div className="relative">
							<select
								onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
									const selectedId = event.target.value;
									if (selectedId === "clear") {
										setSelectedLabels([]); // Clear all selections if the 'Clear' option is chosen
										event.target.value = ''; // Reset select to placeholder after clearing
									} else if (selectedId) {
										const label = labels.find(label => label.id.toString() === selectedId);
										if (label) {
											setSelectedLabels(prev => [...prev, label]);
										}
									}
								}}
								className={ (selectedLabels.length > 0? "bg-purple-dark pr-2" : "bg-gray-1") + ` py-2 rounded-md`}
								style={{
									paddingLeft: selectedLabels.length > 0 ? '30px' : '5px',
									width: selectedLabels.length > 0 ? '160px' : 'auto'
								}}							>
								<option value=""> Internal labels </option>

								{labels.filter(label => !selectedLabels.some(selectedLabel => selectedLabel.id === label.id))
									.map(label => (
										<option key={label.id} value={label.id}>{label.name}</option>
									))}
							</select>
							{selectedLabels.length > 0 &&
								<>
									<div className="comuna-circle-number rounded-full">
										{selectedLabels.length}
									</div>
									<div className="comuna-select-clear bg-purple-dark" onClick={() => setSelectedLabels([])}>
										&times;
									</div>
								</>
							}
						</div>

						<div className="w-full"></div>

						<div className="rounded-3xl border-2 border-gray-300 flex flex-row p-2 mr-4" style={{width:624}}>
							<img src={searchIcon} style={{width:27}} alt="magnifying glass icon" />
							<input 
								onChange={event => setQuery(event.target.value)}
								onKeyDown={SearchQuery}
								className="w-full" 
								type="text" 
								placeholder="Search by organization name, role name, etc.." />
						</div>
					</div>

					<div className="flex flex-wrap ml-5 mt-2">
						{selectedLabels.map((label: any) => (
						<div key={label.id} className="bg-purple-dark rounded-full px-4 py-2 m-1 flex items-center">
							{label.name}
							<button onClick={() => setSelectedLabels(selectedLabels.filter((l: any) => l.id !== label.id))} className="ml-2">
							&times;
							</button>
						</div>
						))}
					</div>
				</div>

				<div style={{ height: "75vh", overflowY: "auto"}}>
				<table className="w-full mt-8" >
					<thead>
						<tr className="text-black">
							<td className="pl-6">Role</td>
							<td className="text-left">Organization</td>
							<td className="text-left">Progress</td>
							<td className="text-left">Creation date</td>
							<td className="text-left">Starting date</td>
							<td className="text-left">Last seen</td>
							<td className="text-right pr-6">Agent</td>
						</tr>
					</thead>
					<RequestRow items={filterRequests} />
				</table>
				</div>


				{assigning && 
				<SearchForComunaAgent 
					updateRequests={UpdateRequests}
					onClose={()=>{ setAssigning(false) }} 
					requestId={selectedRequest.id} 
					roleName={selectedRequest.role_name}
					/>}

			</div>
			<NewRequestForm
					showForm={showNewForm}
					onClose={() => {
						setShowNewForm(false);
					}}
				/>
			<Outlet/>
		</div>
	);
}
