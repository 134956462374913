export function findClosestDate(currentDate: any) {
    const options = [
        GetDateInputValue("yesterday"),
        GetDateInputValue("next_two_weeks"),
        GetDateInputValue("within_a_month"),
        GetDateInputValue("eventually")
    ];
    let closest = options[0]; // Default to the first option
    let minDiff = Number.MAX_SAFE_INTEGER;
    let currentDateTimestamp = new Date(currentDate).getTime();

    options.forEach(option => {
        let optionTimestamp = new Date(option).getTime();
        let diff = Math.abs(optionTimestamp - currentDateTimestamp);

        if (diff < minDiff) {
            minDiff = diff;
            closest = option;
        }
    });
    console.log("closest", closest);
    return closest;
}

export function GetDateInputValue(input: string) {
    //get yesterdays date value
    var date = new Date();

    if (input === "yesterday") {
        date.setDate(date.getDate() + 1);
    }

    if (input === "next_two_weeks") {
        date.setDate(date.getDate() + 14);
    }

    if (input === "within_a_month") {
        date.setDate(date.getDate() + 30);
    }

    if (input === "eventually") {
        date.setDate(date.getDate() + 60);
    }

    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    var value = yyyy + "-" + mm + "-" + dd;
    return value;
}

