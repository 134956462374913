import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { StaffMenuBar } from "../components/StaffMenuBar";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { UserContext } from "../Providers/UserContext";
import { Footer } from "../components/Footer";

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	return { token };
}

export function StaffRoot() {
	const { user }: any = useContext(UserContext);
	const { logOut }: any = useContext(UserContext);

	useEffect(() => {
		console.log("StaffRoot");
		console.log("user", user);
	}, [user]);

	return (
		<div className="flex flex-col min-h-screen">
			<div className="flex flex-row flex-grow">
				<StaffMenuBar />
				<div className="flex flex-col flex-grow">
					<Outlet />
				</div>
			</div>
			<Footer />
			{/* <div className="p-24 text-3xl font-bold underline flex flex-col items-start">
				<ComunaLogo />
				<h1>Comuna staff web client</h1>
				<button className="comuna-primary-button" onClick={logOut}>
					Log out
				</button>
			</div> */}
		</div>
	);
}
